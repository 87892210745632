import VueRouter from "vue-router";
import Vue from "vue";
import Auth from "@/plugins/auth";
import Consts from "@/consts";

import LoginIndex from "@/components/login/Login.vue";
import SharedTopBar from "@/components/shared/TopBar.vue";
import SessionsIndex from "@/components/sessions/Index.vue";
import AdminIndex from "@/components/admin/Index.vue";

import AdminSessionsCentersIndex from "@/components/admin/sessions/centers/Index.vue";
import AdminSessionsCentersEdit from "@/components/admin/sessions/centers/Edit.vue";

import AdminSessionsTopicsIndex from "@/components/admin/sessions/topics/Index.vue";
import AdminSessionsTopicsEdit from "@/components/admin/sessions/topics/Edit.vue";

import AdminSessionsTargetsIndex from "@/components/admin/sessions/targets/Index.vue";
import AdminSessionsTargetsEdit from "@/components/admin/sessions/targets/Edit.vue";

import AdminSessionsTeamsIndex from "@/components/admin/sessions/teams/Index.vue";
import AdminSessionsTeamsEdit from "@/components/admin/sessions/teams/Edit.vue";

import AdminSessionsSeasonsIndex from "@/components/admin/sessions/seasons/Index.vue";
import AdminSessionsSeasonsEdit from "@/components/admin/sessions/seasons/Edit.vue";

import AdminSessionsSettingsIndex from "@/components/admin/sessions/settings/Index.vue";
import AdminSessionsSettingsEdit from "@/components/admin/sessions/settings/Edit.vue";
import AdminSessionsActivitiesIndex from "@/components/admin/sessions/activities/Index.vue";
import AdminSessionsActivitiesEdit from "@/components/admin/sessions/activities/Edit.vue";
import AdminSessionsPackagesIndex from "@/components/admin/sessions/packages/Index.vue";
import AdminSessionsPackagesEdit from "@/components/admin/sessions/packages/Edit.vue";
import AdminAddressbookSchooltypesIndex from "@/components/admin/addressbook/schooltypes/Index.vue";
import AdminAddressbookSchooltypesEdit from "@/components/admin/addressbook/schooltypes/Edit.vue";
import AdminAddressbookFunctionsIndex from "@/components/admin/addressbook/functions/Index.vue";
import AdminAddressbookFunctionsEdit from "@/components/admin/addressbook/functions/Edit.vue";
import AdminPlanningPeriodtypesIndex from "@/components/admin/planning/periodtypes/Index.vue";
import AdminPlanningPeriodtypesEdit from "@/components/admin/planning/periodtypes/Edit.vue";
import AdminPlanningPeriodsIndex from "@/components/admin/planning/periods/Index.vue";
import AdminPlanningPeriodsEdit from "@/components/admin/planning/periods/Edit.vue";
import AdminSettingsGroupsIndex from "@/components/admin/settings/groups/Index.vue";
import AdminSettingsGroupsEdit from "@/components/admin/settings/groups/Edit.vue";

import AdminSettingsUsersIndex from "@/components/admin/settings/users/Index.vue";
import AdminSettingsUsersList from "@/components/admin/settings/users/index/List.vue";
import AdminSettingsUsersContracts from "@/components/admin/settings/users/index/Contracts.vue";
import AdminSettingsUsersProfilesMatrix from "@/components/admin/settings/users/index/ProfilesMatrix.vue";
import AdminSettingsUsersEdit from "@/components/admin/settings/users/Edit.vue";
import AdminSettingsUsersShow from "@/components/admin/settings/users/Show.vue";
import AdminSettingsUsersShowInfo from "@/components/admin/settings/users/show/Info.vue";
import AdminSettingsUsersShowContracts from "@/components/admin/settings/users/show/Contracts.vue";

import AdminSettingsUsersShowSkills from "@/components/admin/settings/users/show/Skills.vue";
import AdminSettingsUsersShowSkillsEdit from "@/components/admin/settings/users/show/skills/Edit.vue";
import AdminSettingsUsersShowSkillsSeasons from "@/components/admin/settings/users/show/skills/Seasons.vue";

import AdminSettingsUsersShowHistoric from "@/components/admin/settings/users/show/Historic.vue";

import AdminSettingsValuesIndex from "@/components/admin/settings/values/Index.vue";
import SessionsEditIndex from "@/components/sessions/edit/Index.vue";
import SessionsEvalsIndex from "@/components/sessions/evals/Index.vue";

import ResetPasswordIndex from "@/components/reset-password/index.vue";
import ResetPasswordVerifyCode from "@/components/reset-password/verify-code.vue";
import ResetPasswordReset from "@/components/reset-password/reset-password.vue";
import CreateAccountIndex from "@/components/create-account/index.vue";
import ConfirmAccountIndex from "@/components/create-account/confirm.vue";
import AddressIndex from "@/components/address-book/Index.vue";
import AddressShow from "@/components/address-book/show.vue";
import AddressShowInfo from "@/components/address-book/show/index.vue";
import AddressShowContacts from "@/components/address-book/show/contacts.vue";
import AddressShowEvals from "@/components/address-book/show/evaluations.vue";
import AddressShowSessions from "@/components/address-book/show/sessions.vue";
import SessionsShowHistoric from "@/components/sessions/show/Historic.vue";
import SessionsShowMaps from "@/components/sessions/show/Maps.vue";
import SessionsShowTrainerEvals from "@/components/sessions/evals/TrainerEvals.vue";
import SessionsEvalReview from "@/components/sessions/evals/Review.vue";
import SessionsShowTeacherEvals from "@/components/sessions/evals/TeacherEvals.vue";
import SessionsShowInfo from "@/components/sessions/show/Index.vue";
import SessionsShowSettings from "@/components/sessions/show/Settings.vue";
import SessionsShowPackage from "@/components/sessions/show/Package.vue";
import SessionsShowReview from "@/components/sessions/show/Review.vue";
import SessionsShowIndex from "@/components/sessions/show.vue";
import StatisticsIndex from "@/components/statistics/Index.vue";
import StatisticsEntities from "@/components/statistics/Entities.vue";
import StatisticsTypesTopics from "@/components/statistics/TypesTopics.vue";
import StatisticsTrainers from "@/components/statistics/Trainers.vue";
import StatisticsEvals from "@/components/statistics/Evals.vue";
import StatisticsTarget from "@/components/statistics/TargetGroups.vue";
import StatisticsUniv from "@/components/statistics/Univ.vue";
import StatisticsEvents from "@/components/statistics/Events.vue";
import DocumentsIndex from "@/components/documents/Index.vue";
import SessionsMulti from "@/components/sessions/Multi.vue";
import Planning from "@/components/sessions/Planning.vue";
import PlanningMobile from "@/components/sessions/planning/Mobile.vue";
import LogisticsIndex from "@/components/sessions/Logistic.vue";
import GroupsIndex from "@/components/Groups.vue";

import SessionsApi from "@/api/sessions";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/login"
    },
    {
      name: "sessions-index",
      path: "/sessions",
      components: {
        top: SharedTopBar,
        body: SessionsIndex
      },
      meta: {
        auth: true
      }
    },
    {
      name: "sessions-add-multi",
      path: "/sessions/multi",
      components: {
        top: SharedTopBar,
        body: SessionsMulti
      },
      meta: {
        auth: true,
        ct: true
      }
    },
    {
      name: "sessions-edit",
      path: "/sessions/:id/edit",
      components: {
        top: SharedTopBar,
        body: SessionsEditIndex
      },
      meta: {
        auth: true,
        ct: true
      }
    },
    {
      name: "sessions-evals",
      path: "/sessions/:id/evaluations",
      components: {
        top: SharedTopBar,
        body: SessionsEvalsIndex
      },
      meta: {
        auth: true
      },
      children: [
        {
          name: "sessions-show-trainer-evals",
          path: "trainers",
          component: SessionsShowTrainerEvals
        },
        {
          name: "sessions-show-teacher-evals",
          path: "teachers",
          component: SessionsShowTeacherEvals
        },
        {
          name: "sessions-edit-review",
          path: "review",
          component: SessionsEvalReview
        }
      ]
    },
    {
      name: "sessions-show",
      path: "/sessions/:id/show",
      components: {
        top: SharedTopBar,
        body: SessionsShowIndex
      },
      meta: {
        auth: true,
        ctrlStatus: true
      },
      children: [
        {
          name: "sessions-show-info",
          path: "info",
          component: SessionsShowInfo
        },
        {
          name: "sessions-show-settings",
          path: "settings",
          component: SessionsShowSettings
        },
        {
          name: "sessions-show-package",
          path: "package",
          component: SessionsShowPackage
        },
        {
          name: "sessions-show-review",
          path: "review",
          component: SessionsShowReview
        },
        {
          name: "sessions-show-historic",
          path: "historic",
          component: SessionsShowHistoric
        },
        {
          name: "sessions-show-maps",
          path: "maps",
          component: SessionsShowMaps
        }
      ]
    },
    {
      name: "planning-index",
      path: "/planning",
      components: {
        top: SharedTopBar,
        body: Planning
      },
      meta: {
        auth: true
      }
    },
    {
      name: "planning-mobile",
      path: "/planning/mobile",
      components: {
        top: SharedTopBar,
        body: PlanningMobile
      },
      meta: {
        auth: true
      }
    },
    {
      name: "address-index",
      path: "/address",
      components: {
        top: SharedTopBar,
        body: AddressIndex
      },
      meta: {
        auth: true
      }
    },
    {
      name: "address-show",
      path: "/address/:id/show",
      components: {
        top: SharedTopBar,
        body: AddressShow
      },
      meta: {
        auth: true
      },
      children: [
        { name: "address-show-info", path: "info", component: AddressShowInfo },
        {
          name: "address-show-contacts",
          path: "contacts",
          component: AddressShowContacts
        },
        {
          name: "address-show-evals",
          path: "evaluations",
          component: AddressShowEvals
        },
        {
          name: "address-show-sessions",
          path: "sessions",
          component: AddressShowSessions
        }
      ]
    },
    {
      name: "logistics-index",
      path: "/logistics",
      components: {
        top: SharedTopBar,
        body: LogisticsIndex
      },
      meta: {
        auth: true,
        acl: () => Auth.user.is_admin && (Auth.user.application == Consts.APPLICATION.ALL || Auth.user.application == Consts.APPLICATION.BEESECURE)
      }
    },
    {
      name: "groups-index",
      path: "/groups",
      components: {
        top: SharedTopBar,
        body: GroupsIndex
      },
      meta: {
        auth: true
      }
    },
    {
      name: "topten-index",
      path: "/topten",
      components: {
        top: SharedTopBar
        // body: SessionsIndex
      },
      meta: {
        auth: true
      }
    },
    {
      name: "documents-index",
      path: "/documents",
      components: {
        top: SharedTopBar,
        body: DocumentsIndex
      },
      meta: {
        auth: true
      }
    },
    {
      name: "admin-index",
      path: "/admin",
      components: {
        top: SharedTopBar,
        body: AdminIndex
      },
      meta: {
        auth: true,
        admin: true
      },
      redirect: { name: "admin-sessions-topics-index" },
      children: [
        {
          name: "admin-sessions-centers-index",
          path: "sessions/centers",
          components: {
            adminbody: AdminSessionsCentersIndex
          }
        },
        {
          name: "admin-sessions-centers-edit",
          path: "sessions/centers/:id",
          components: {
            adminbody: AdminSessionsCentersEdit
          }
        },
        {
          name: "admin-sessions-topics-index",
          path: "sessions/topics",
          components: {
            adminbody: AdminSessionsTopicsIndex
          }
        },
        {
          name: "admin-sessions-topics-edit",
          path: "sessions/topics/:id",
          components: {
            adminbody: AdminSessionsTopicsEdit
          }
        },
        {
          name: "admin-sessions-targets-index",
          path: "sessions/targets",
          components: {
            adminbody: AdminSessionsTargetsIndex
          }
        },
        {
          name: "admin-sessions-targets-edit",
          path: "sessions/targets/:id",
          components: {
            adminbody: AdminSessionsTargetsEdit
          }
        },
        {
          name: "admin-sessions-teams-index",
          path: "sessions/teams",
          components: {
            adminbody: AdminSessionsTeamsIndex
          }
        },
        {
          name: "admin-sessions-teams-edit",
          path: "sessions/teams/:id",
          components: {
            adminbody: AdminSessionsTeamsEdit
          }
        },
        {
          name: "admin-sessions-seasons-index",
          path: "sessions/seasons",
          components: {
            adminbody: AdminSessionsSeasonsIndex
          }
        },
        {
          name: "admin-sessions-seasons-edit",
          path: "sessions/seasons/:id",
          components: {
            adminbody: AdminSessionsSeasonsEdit
          }
        },
        {
          name: "admin-sessions-settings-index",
          path: "sessions/settings",
          components: {
            adminbody: AdminSessionsSettingsIndex
          }
        },
        {
          name: "admin-sessions-settings-edit",
          path: "sessions/settings/:id",
          components: {
            adminbody: AdminSessionsSettingsEdit
          }
        },
        {
          name: "admin-sessions-activities-index",
          path: "sessions/activities",
          components: {
            adminbody: AdminSessionsActivitiesIndex
          }
        },
        {
          name: "admin-sessions-activities-edit",
          path: "sessions/activities/:id",
          components: {
            adminbody: AdminSessionsActivitiesEdit
          }
        },
        {
          name: "admin-sessions-packages-edit",
          path: "sessions/packages/:id",
          components: {
            adminbody: AdminSessionsPackagesEdit
          }
        },
        {
          name: "admin-sessions-packages-index",
          path: "sessions/packages",
          components: {
            adminbody: AdminSessionsPackagesIndex
          }
        },
        {
          name: "admin-addressbook-schooltypes-index",
          path: "addressbook/schooltypes",
          components: {
            adminbody: AdminAddressbookSchooltypesIndex
          }
        },
        {
          name: "admin-addressbook-schooltypes-edit",
          path: "addressbook/schooltypes/:id",
          components: {
            adminbody: AdminAddressbookSchooltypesEdit
          }
        },
        {
          name: "admin-addressbook-functions-index",
          path: "addressbook/functions",
          components: {
            adminbody: AdminAddressbookFunctionsIndex
          }
        },
        {
          name: "admin-addressbook-functions-edit",
          path: "addressbook/functions/:id",
          components: {
            adminbody: AdminAddressbookFunctionsEdit
          }
        },
        {
          name: "admin-planning-periodtypes-index",
          path: "planning/periodtypes",
          components: {
            adminbody: AdminPlanningPeriodtypesIndex
          }
        },
        {
          name: "admin-planning-periodtypes-edit",
          path: "planning/periodtypes/:id",
          components: {
            adminbody: AdminPlanningPeriodtypesEdit
          }
        },
        {
          name: "admin-planning-periods-index",
          path: "planning/periods",
          components: {
            adminbody: AdminPlanningPeriodsIndex
          }
        },
        {
          name: "admin-planning-periods-edit",
          path: "planning/periods/:id",
          components: {
            adminbody: AdminPlanningPeriodsEdit
          }
        },
        {
          name: "admin-settings-groups-index",
          path: "settings/groups",
          components: {
            adminbody: AdminSettingsGroupsIndex
          }
        },
        {
          name: "admin-settings-groups-edit",
          path: "settings/groups/:id",
          components: {
            adminbody: AdminSettingsGroupsEdit
          }
        },
        {
          name: "admin-settings-users-index",
          path: "settings/users",
          components: {
            adminbody: AdminSettingsUsersIndex
          },
          meta: {
            auth: true
          },
          redirect: { name: "admin-settings-users-list" },
          children: [
            {
              name: "admin-settings-users-list",
              path: "list",
              component: AdminSettingsUsersList
            },
            {
              name: "admin-settings-users-profiles-matrix",
              path: "profiles-matrix",
              component: AdminSettingsUsersProfilesMatrix
            },
            {
              name: "admin-settings-users-contracts",
              path: "contracts",
              component: AdminSettingsUsersContracts
            }
          ]
        },
        {
          name: "admin-settings-users-edit",
          path: "settings/users/:id",
          components: {
            adminbody: AdminSettingsUsersEdit
          }
        },
        {
          name: "admin-settings-users-show",
          path: "settings/users/:id/show",
          components: {
            adminbody: AdminSettingsUsersShow
          },
          meta: {
            auth: true
          },
          redirect: { name: "admin-settings-users-show-info" },
          children: [
            {
              name: "admin-settings-users-show-info",
              path: "info",
              component: AdminSettingsUsersShowInfo
            },
            {
              name: "admin-settings-users-show-contracts",
              path: "contracts",
              component: AdminSettingsUsersShowContracts
            },
            {
              name: "admin-settings-users-show-skills",
              path: "skills",
              component: AdminSettingsUsersShowSkills
            },
            {
              name: "admin-settings-users-show-skills-edit",
              path: "skills/:skill_id",
              component: AdminSettingsUsersShowSkillsEdit
            },
            {
              name: "admin-settings-users-show-skills-seasons",
              path: "skills/:skill_id/seasons",
              component: AdminSettingsUsersShowSkillsSeasons
            },
            {
              name: "admin-settings-users-show-historic",
              path: "historic",
              component: AdminSettingsUsersShowHistoric
            }
          ]
        },
        {
          name: "admin-settings-values-index",
          path: "settings/values",
          components: {
            adminbody: AdminSettingsValuesIndex
          }
        }
      ]
    },
    {
      name: "login",
      path: "/login",
      components: {
        body: LoginIndex
      },
      meta: {
        auth: false
      }
    },
    {
      name: "reset-password-index",
      path: "/reset-password",
      components: {
        body: ResetPasswordIndex
      },
      meta: {
        auth: false
      }
    },
    {
      name: "reset-password-verify-code",
      path: "/reset-password/:id/verify-code",
      components: {
        body: ResetPasswordVerifyCode
      },
      meta: {
        auth: false
      }
    },
    {
      name: "reset-password-reset",
      path: "/reset-password/:id/reset",
      components: {
        body: ResetPasswordReset
      },
      meta: {
        auth: false
      }
    },
    {
      name: "create-account-index",
      path: "/create-account",
      components: {
        body: CreateAccountIndex
      },
      meta: {
        auth: false
      }
    },
    {
      name: "confirm-account-index",
      path: "/confirm-account/:token",
      components: {
        body: ConfirmAccountIndex
      },
      meta: {
        auth: false
      }
    },
    {
      name: "stats-index",
      path: "/statistics",
      components: {
        top: SharedTopBar,
        body: StatisticsIndex
      },
      meta: {
        auth: true
      },
      redirect: { name: "statistics-entities" },
      children: [
        {
          name: "statistics-entities",
          path: "entities",
          component: StatisticsEntities
        },
        {
          name: "statistics-types-topics",
          path: "types-topics",
          component: StatisticsTypesTopics
        },
        {
          name: "statistics-trainers",
          path: "trainers",
          component: StatisticsTrainers
        },
        {
          name: "statistics-evals",
          path: "evaluations/:sessionType",
          component: StatisticsEvals
        },
        {
          name: "statistics-target",
          path: "target-groups/:sessionType",
          component: StatisticsTarget
        },
        {
          name: "statistics-univ",
          path: "university",
          component: StatisticsUniv
        },
        {
          name: "statistics-events",
          path: "events",
          component: StatisticsEvents
        }
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    if (to.name) {
      /*Message.error({
                title: 'Erreur de route',
                message: 'Aucune route ne possède le nom "' + to.name + '". Cette page est probablement en chantier.'
            });*/
      return;
    }
  }

  if (to.name && to.matched[0].meta.auth && !Auth.authenticated) {
    // This route requires an authentication, and user isn't
    // authenticated. Redirect him to the login page.
    router.push({ name: "login" });
  } else if (to.name && to.matched[0].meta.ct && !Auth.ct) {
    router.push({ name: "sessions-index" });
  } else if (to.name && to.matched[0].meta.admin && !Auth.user.is_admin) {
    router.push({ name: "sessions-index" });
  } else if (to.name && to.matched[0].meta.acl && !to.matched[0].meta.acl()) {
    router.push({ name: "sessions-index" });
  } else {
    next();
  }

  if(to.name && to.matched[0].meta.ctrlStatus) {
    //on doit vérifier que la session à laquelle on tente d'accéder est accessible par l'utilisateur connecté
    SessionsApi.get(to.params.id).then(data => {
      const isTrainer = Auth.user.roles.indexOf(Consts.TRAINER) > -1
      const allowedStatusForATrainer = [Consts.RELEASE, Consts.TOVALIDATE, Consts.BLOCKED, Consts.TOEVALUATE]
      if(!isTrainer || allowedStatusForATrainer.indexOf(data.data.status) > -1) {
        next()
      }
      else {
        router.push({ name: "sessions-index" });
      }
    })
  }
});

export default router;
