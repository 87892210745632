import moment from 'moment'

export default {
    methods: {
        formatDate (x) {
            return moment(x).format('DD.MM.YYYY')
        },
        formatDateTime (x) {
            return moment(x).format('YYYY.MM.DD HH:mm')
        },
        formatDateTimeHuman (x) {
            return moment(x).format('DD.MM.YYYY HH:mm')
        },
        formatTime (x) {
            return moment(x).format('HH:mm')
        },
        formatDateHuman (date) {
            return moment(date).format('ddd, MMM DD YYYY, HH:mm')
        },
        formatDay (date) {
            return moment(date).format('DD')
        },
        formatMonthYear (date) {
            return moment(date).format('MMM YYYY')
        },
        formatDayHuman (date) {
            return moment(date).format('ddd, DD.MM.YYYY')
        },
        getDecimalHours(start, end) {
            const sTab = start.split(':');
            const eTab = end.split(':');
            if(sTab.length != 2 || eTab.length != 2) {
                return 0;
            }
            const sH = parseInt(sTab[0]);
            const sM = parseInt(sTab[1]);

            const eH = parseInt(eTab[0]);
            const eM = parseInt(eTab[1]);

            return Math.abs((eH + (eM/60)) - (sH + (sM/60)));
        }
    }
}
