<template>
  <div class="ma-3">
    <div class="title pb-2">
      New
      <template v-if="typeof sessionTypeStr(session.session_type) === 'string'">
        {{ sessionTypeStr(session.session_type).toLowerCase() }}
      </template>
      session
    </div>
    <router-link
      :to="{name: 'sessions-index'}"
      class="link-unstyled"
    >
      <v-icon
        color="primary"
        size="16"
      >
        mdi-arrow-left
      </v-icon>
      Back to the list
    </router-link>
    <session-form
      :value="session"
      @save="onSessionSave"
    />
  </div>
</template>

<script>
    import SessionForm from "@/components/shared/SessionForm.vue"
    import SessionsMixin from '@/mixins/sessions'
    import Auth from '@/plugins/auth'

    export default {
        components: {SessionForm},
        mixins: [SessionsMixin],
        data () {
            return {
                session: {
                    organization_id: null,
                    organization: null,
                    contact_id: null,
                    contact: null,
                    contact2_id: null,
                    contact2: null,
                    session_type: 0,
                    dates: [],
                    users_groups: [],
                    events_settings: [],
                    activities: [],
                    ticket_number: null,
                    delivery_address: null,
                    beamer: true,
                    screen: true,
                    loudspeak: true,
                    internet: true,
                    socket: true,
                    usb: true,
                    special_training: false,
                    application: Auth.user.application,
                    topic_id: null,
                    team_id: null,
                    season_id: null,
                    animator_role: null,
                },
            }
        },
        beforeMount () {
            const queryType = parseInt(this.$route.query.type, 10);

            if (queryType <= 0 || queryType > 9 || isNaN(queryType)) {
                this.$router.replace({name: 'sessions-index'});
            } else {
                this.session.session_type = queryType;
            }

            const queryApp = parseInt(this.$route.query.application)
            if(queryApp){
                this.session.application = queryApp
            }
        },
        methods: {
            onSessionSave(){
                if (this.session.id) {
                    this.$router.push({name: 'sessions-show-info', params: {id: this.session.id}});
                } else {
                    this.$router.push({name: 'sessions-index'})
                }
            }
        }
    }
</script>
