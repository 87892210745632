<template>
  <div class="ma-3">
    <div class="title mb-3">
      Sessions <v-icon>mdi-chevron-right</v-icon> Seasons management <v-icon>mdi-chevron-right</v-icon> {{ isNew ? 'New' : season.name }}
    </div>

    <v-form
      ref="form"
      v-model="formValid"
      layout="column"
      layout-align="center center"
      @submit.prevent="save"
    >
      <div>
        <div class="title font-weight-light mb-3">
          Season information
        </div>
        <v-select
          v-model="season.team_id"
          v-focus
          :items="teams"
          item-text="name"
          item-value="id"
          outline
          label="Team"
          :rules="[ v => !!v || 'This field is required' ]"
          @change="onTeamChange"
        />
        <v-select
          v-if="season.team_id"
          v-model="season.topic_id"
          v-focus
          :items="availableTopics"
          item-text="name"
          item-value="id"
          outline
          label="Concept"
          :rules="[ v => !!v || 'This field is required' ]"
        />
        <div
          layout="row"
          layout-align="start center"
        >
          <date-field
            v-model="season.start"
            label="Start date"
            required
            :form="$refs.form"
            class="field-width"
          />
        </div>
        <div
          layout="row"
          layout-align="start center"
          class="my-3"
        >
          <date-field
            v-model="season.end"
            label="End date"
            required
            :form="$refs.form"
            class="field-width"
          />
        </div>
      </div>
      <v-btn
        depressed
        color="primary"
        @click="save"
      >
        Save
      </v-btn>
      <router-link
        :to="{name: 'admin-sessions-seasons-index'}"
        class="grey--text"
      >
        Cancel
      </router-link>
    </v-form>
  </div>
</template>

<script>
    import Params from '@/api/params'
    import DateMixin from '@/mixins/date'
    import Consts from '@/consts'
    import DateField from '@/components/shared/DateField.vue'

    export default {
        components: {DateField},
        mixins: [DateMixin],
        data () {
            return {
                season: {
                    team_id: null,
                    topic_id: null,
                    start: null,
                    end: null
                },
                menuBegin: false,
                menuEnd: false,
                teams: [],
                topics: [],
                formValid: false
            }
        },
        computed: {
            availableTopics(){
                if(!this.season.team_id){
                    return []
                }

                const arr = []

                this.topics.forEach(t => {
                    if(t.teams && t.teams.length){
                        t.teams.forEach(c => {
                            if(c.id === this.season.team_id){
                                arr.push(t)
                            }
                        })
                    }
                })

                return arr
            },
            isNew () {
                return this.$route.params.id === 'new';
            }
        },
        mounted () {
            if (!this.isNew) {
                this.fetch();
            } else {
                this.fetchParams()
            }
        },
        methods: {
            onTeamChange(){
                if(this.season.topic_id){
                    this.$nextTick(() => {
                        let found = false
                        this.availableTopics.forEach(t => {
                            if(t.id === this.season.topic_id){
                                found = true
                            }
                        })

                        if(!found){
                            this.season.topic_id = null
                        }
                    })
                }
            },
            fetch () {
                Params.get('seasons', this.$route.params.id).then(res => {
                    this.season = res.data;
                    this.fetchParams()
                })
            },
            fetchParams(){
                Params.getList('teams', {is_enabled: 1, current_id: this.season.team_id}).then(res => {
                    this.teams = res.data
                })
                Params.getList('topics', {is_enabled: 1, current_id: this.season.topic_id, application: Consts.APPLICATION.FREELANCES}).then(res => {
                    this.topics = res.data
                })
            },
            save () {
                if (!this.$refs.form.validate()) {
                    return;
                }

                if (this.isNew) {
                    Params.create('seasons', this.season).then(() => {
                        this.$router.push({name: 'admin-sessions-seasons-index'});
                        this.$snotify.success(`Season has been created`)
                    })
                } else {
                    Params.update('seasons', this.season.id, this.season).then(() => {
                        this.$router.push({name: 'admin-sessions-seasons-index'});
                        this.$snotify.success(`Season has been updated`)
                    })
                }
            }
        }
    }
</script>
