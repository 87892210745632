<template>
  <div class="ma-3">
    <div class="title mb-3">
      Settings <v-icon>mdi-chevron-right</v-icon> Groups <v-icon>mdi-chevron-right</v-icon> {{ isNew ? 'New' : group.name }}
    </div>

    <v-form
      ref="form"
      v-model="formValid"
      layout="column"
      layout-align="center center"
      @submit.prevent="save"
    >
      <div>
        <div class="title font-weight-light mb-3">
          Group information
        </div>
        <v-text-field
          v-model="group.name"
          outline
          label="Name"
          class="field-width"
          :rules="[ v => !!v || 'This field is required' ]"
        />
        <v-select
          v-model="group.role"
          v-focus
          outline
          label="Role"
          class="field-width"
          :rules="[ v => !!v || 'This field is required' ]"
          :items="roles"
        />
        <v-checkbox
          v-model="group.is_event"
          label="This group is the events group"
          :true-value="1"
          :false-value="0"
        />
      </div>

      <v-btn
        depressed
        color="primary"
        @click="save"
      >
        Save
      </v-btn>
      <router-link
        :to="{name: 'admin-settings-groups-index'}"
        class="grey--text"
      >
        Cancel
      </router-link>
    </v-form>
  </div>
</template>

<script>
    import Params from '@/api/params'
    import Consts from '@/consts'

    export default {
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from.name
            })
        },
        data () {
            return {
                group: {
                    name: null
                },
                formValid: false,
                roles: [
                    { text: 'Coordination', value: Consts.COORDINATION },
                    { text: 'Trainer / Animator', value: Consts.TRAINER },
                ],
                prevRoute: null
            }
        },
        computed: {
            isNew () {
                return this.$route.params.id === 'new';
            }
        },
        mounted () {
            if (!this.isNew) {
                this.fetch();
            }
        },
        methods: {
            fetch () {
                Params.get('groups', this.$route.params.id).then(res => {
                    this.group = res.data;
                })
            },
            save () {
                if (!this.$refs.form.validate()) {
                    return;
                }

                if (this.isNew) {
                    Params.create('groups', this.group).then(() => {
                        this.$router.push({name: this.prevRoute !== null ? this.prevRoute : 'admin-settings-groups-index'});
                        this.$snotify.success(`The group "${this.group.name}" has been created`)
                    })
                } else {
                    Params.update('groups', this.group.id, this.group).then(() => {
                        this.$router.push({name: this.prevRoute !== null ? this.prevRoute : 'admin-settings-groups-index'});
                        this.$snotify.success(`The group "${this.group.name}" has been updated`)
                    })
                }
            }
        }
    }
</script>
