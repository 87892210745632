<template>
  <div
    v-if="loading"
    class="ma-5 text-xs-center"
  >
    <v-progress-circular
      indeterminate
      :size="32"
      color="accent"
    />
  </div>
  <div
    v-else
    class="ma-3"
  >
    <div
      v-if="session.id"
      class="title pb-2"
    >
      {{ sessionTypeStrLong(session.session_type) }} session #<router-link
        class="black--text"
        :to="{name: 'sessions-show-info', params: {id: session.id}}"
      >
        {{ session.reference }}
      </router-link>
    </div>
    <router-link
      :to="{name: 'sessions-index'}"
      class="link-unstyled"
    >
      <v-icon
        color="primary"
        size="16"
      >
        mdi-arrow-left
      </v-icon>
      Back to the list
    </router-link>

    <div
      layout="row"
      class="mt-3"
    >
      <v-list class="sidebar">
        <template v-for="(v, k) in sidebarOptions">
          <v-list-tile
            v-if="!v.types || v.types.indexOf(session.session_type) >= 0"
            :key="k"
            :to="{name: v.link, params: {id: $route.params.id}}"
          >
            <v-list-tile-avatar>
              <v-icon>{{ v.icon }}</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-title>
              {{ v.text }}
            </v-list-tile-title>
          </v-list-tile>
        </template>
      </v-list>
      <router-view
        class="mx-3"
        flex
        :is-admin.sync="isAdmin"
        :is-coord.sync="isCoord"
        :is-trainer.sync="isTrainer"
        :session.sync="session"
      />
    </div>
  </div>
</template>

<script>
    import Sessions from '@/api/sessions'
    import SessionsMixin from '@/mixins/sessions'
    import Auth from '@/plugins/auth'
    import Consts from '@/consts'
    import moment from 'moment';

    export default {
        mixins: [SessionsMixin],
        data () {
            return {
                sidebarOptions: [
                    { text: 'Trainer evaluations', icon: 'mdi-marker', link: 'sessions-show-trainer-evals', types: [Consts.SCHOOL, Consts.ADULTS, Consts.YOUTH, Consts.TEACHERS, Consts.OTHER, Consts.INTERNAL, Consts.FREELANCES_TRAINING, Consts.ACTIVITY] },
                    { text: 'Teacher evaluations', icon: 'mdi-marker', link: 'sessions-show-teacher-evals', types: [Consts.SCHOOL, Consts.ACTIVITY] },
                    { text: 'Review', icon: 'mdi-message-draw', link: 'sessions-edit-review', types: [Consts.EVENTS] }
                ],
                loading: false,
                session: {
                    organization_id: null,
                    organization: null,
                    contact_id: null,
                    contact: null,
                    contact2_id: null,
                    contact2: null,
                    session_type: 0,
                    dates: [],
                    users_groups: [],
                    events_settings: [],
                    activities: []
                },
                isTrainer: false,
                isCoord: false,
                isAdmin: false,
            }
        },

        mounted() {
            if (this.$route.params.id === 'new') {
                const queryType = parseInt(this.$route.query.type);

                if (queryType <= 0 || queryType > 6 || isNaN(queryType)) {
                    this.$router.replace({name: 'sessions-index'});
                } else {
                    this.session.session_type = this.$route.query.type;
                }
            } else {
                this.loading = true;
                Sessions.get(this.$route.params.id).then(res => {
                    this.session = res.data;
                    this.loading = false;

                    if (this.session.dates.length > 0) {
                        // Parse dates into split date/time
                        for (let i = 0; i < this.session.dates.length; ++i) {
                            let d = this.session.dates[i];

                            d.date_start = moment(d.start).format('YYYY-MM-DD');
                            d.date_start_text = moment(d.start).format('YYYY-MM-DD');
                            d.clock_start = moment(d.start).format('HH:mm:ss');
                            d.clock_start_text = moment(d.start).format('HH:mm');
                            d.clock_end = moment(d.end).format('HH:mm:ss');
                            d.clock_end_text = moment(d.end).format('HH:mm');

                            this.session.dates[i] = d;
                        }
                    }
                }, () => {
                    this.$router.push({name: 'sessions-index'})
                })
            }
            this.loadUser();
        },

        methods: {
            loadUser(){
                if(Auth.user.is_admin){
                    this.isAdmin = true;
                }
                if(Auth.user.roles.indexOf(Consts.COORDINATION) > -1){
                    this.isCoord = true;
                }
                if(Auth.user.roles.indexOf(Consts.TRAINER) > -1){
                    this.isTrainer = true;
                }
            }
        }
    }
</script>

<style scoped>
.sidebar {
  border-right: 1px solid #ddd;
  width: 250px;
  background: transparent;
}
</style>
