<template>
  <div class="ma-3">
    <div class="title mb-3">
      Sessions <v-icon>mdi-chevron-right</v-icon> Teams management
      <v-icon>mdi-chevron-right</v-icon> {{ isNew ? "New" : team.name }}
    </div>

    <v-form
      ref="form"
      v-model="formValid"
      layout="column"
      layout-align="center center"
      @submit.prevent="save"
    >
      <div>
        <div class="title font-weight-light mb-3">
          Team information
        </div>
        <v-text-field
          v-model="team.name"
          outline
          label="Name"
          class="field-width"
          :rules="[v => !!v || 'This field is required']"
        />
        <v-select
          v-model="team.center_id"
          v-focus
          label="Center"
          :items="centers"
          item-text="name"
          item-value="id"
          class="field-width"
          outline
          :rules="[v => !!v || 'This field is required']"
        />
        <v-text-field
          v-model="team.sync_id"
          outline
          label="Sync code"
          class="field-width"
        />
        <v-checkbox
          v-if="team.sync_id === 'sport'"
          v-model="team.include_divers"
          label="Include &quot;divers&quot;"
          :true-value="1"
          :false-value="0"
        />
      </div>

      <v-btn
        depressed
        color="primary"
        @click="save"
      >
        Save
      </v-btn>
      <router-link
        :to="{ name: 'admin-sessions-teams-index' }"
        class="grey--text"
      >
        Cancel
      </router-link>
    </v-form>
  </div>
</template>

<script>
import Params from "@/api/params";

export default {
  data() {
    return {
      team: {
        name: null,
        sync_id: null,
        include_divers: null,
        center_id: null
      },
      formValid: false,
      centers: []
    };
  },
  computed: {
    isNew() {
      return this.$route.params.id === "new";
    }
  },
  async mounted() {
    if (!this.isNew) {
      await this.fetch();
    }
    Params.getList("centers", {is_enabled: 1, current_id: this.isNew ? null : this.team.center_id, sortBy: "name"}).then(res => {
      this.centers = res.data
    })
  },
  methods: {
    async fetch() {
      const teamResp = await Params.get("teams", this.$route.params.id)
      this.team = teamResp.data
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.isNew) {
        Params.create("teams", this.team).then(() => {
          this.$router.push({ name: "admin-sessions-teams-index" });
          this.$snotify.success(
            `Team "${this.team.name}" has been created`
          );
        });
      } else {
        Params.update("teams", this.team.id, this.team).then(() => {
          this.$router.push({ name: "admin-sessions-teams-index" });
          this.$snotify.success(
            `Team "${this.team.name}" has been updated`
          );
        });
      }
    }
  }
};
</script>
