<template>
  <v-card
    class="ds-calendar-event-popover-card"
    :class="classes"
  >
    <v-toolbar
      extended
      flat
      :style="styleHeader"
      :color="details.session ? sessionTypeColorLighten(details.session.session_type) : null"
    >
      <v-toolbar-title
        slot="extension"
        style="display: block; width: unset"
      >
        <v-avatar
          v-if="details.status"
          :color="details.status.color"
          class="black--text font-weight-medium"
          :class="{'white--text' : (details.session.status == 0)}"
        >
          {{ details.status.str }}
        </v-avatar>

        {{ details.title.replace(' #'+details.session.reference, '') }}<v-badge
          class="text-no-wrap mr-3 ml-2"
          color="transparent"
        >
          <v-icon
            slot="badge"
            small
            class="mr-1"
          >
            <template v-if="details.session.application === Consts.APPLICATION.BEESECURE">
              icon-bee48
            </template>
            <template v-else>
              icon-kayak48
            </template>
          </v-icon>
          <router-link :to="{ name: 'sessions-show-info', params: { id: details.session.id } }">
            #{{ details.session.reference }}
          </router-link>
        </v-badge>
        <v-chip
          v-if="details.session_type"
          :color="sessionTypeColorLight(details.session.session_type)"
        >
          {{ details.session_type.str }}
        </v-chip>
      </v-toolbar-title>

      <v-btn
        v-if="details.session"
        color="secondary"
        small
        absolute
        bottom
        left
        fab
        icon
        style="display: block; width: 40px"
        @click="edit(details.session.id)"
      >
        <v-icon>mdi mdi-{{ isReadOnly ? 'eye' : 'pencil' }}</v-icon>
      </v-btn>
      <slot
        name="eventPopoverToolbarLeft"
        v-bind="slotData"
      />
      <v-spacer />
      <slot
        name="eventPopoverToolbarRight"
        v-bind="slotData"
      />
      <v-spacer />
      <slot
        name="eventPopoverToolbarClose"
        v-bind="slotData"
      >
        <v-btn
          icon
          :style="styleButton"
          @click="quit"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </slot>
    </v-toolbar>
    <v-card-text>
      <slot
        name="eventPopoverBodyTop"
        v-bind="slotData"
      />
      <v-list dense>
        <v-list-tile>
          <v-list-tile-avatar>
            <v-icon>access_time</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <slot
              name="eventPopoverOccurs"
              v-bind="slotData"
            >
              <v-list-tile-title>
                <span v-if="details.dates && details.dates.length > 1">
                  <strong>Working hours: </strong>{{ formatDayHuman(details.dates[0].start) }} {{ formatTime(details.dates[0].start) }}-{{ formatDayHuman(details.dates[details.dates.length - 1].end) }} {{ formatTime(details.dates[details.dates.length - 1].end) }}
                </span>
                <span v-else-if="details.dates && details.dates.length == 1">
                  <strong>Working hours: </strong>{{ formatDayHuman(details.dates[0].start) }}
                  {{ formatTime(details.dates[0].start) }}-{{ formatTime(details.dates[0].end) }}
                </span>
                <span v-else-if="details.period">
                  <strong>Working hours: </strong>{{ formatDayHuman(details.period.start) }} {{ formatTime(details.period.start) }}-{{ formatDayHuman(details.period.end) }} {{ formatTime(details.period.end) }}
                </span>
              </v-list-tile-title>
               <v-list-tile-title>
                <span v-if="details.dates && details.dates.length > 1">
                  <strong>Participants presence: </strong>{{ formatDayHuman(details.dates[0].participants_start) }} {{ formatTime(details.dates[0].participants_start) }}-{{ formatDayHuman(details.dates[details.dates.length - 1].participants_end) }} {{ formatTime(details.dates[details.dates.length - 1].participants_end) }}
                </span>
                <span v-else-if="details.dates && details.dates.length == 1">
                  <strong>Participants presence: </strong>{{ formatDayHuman(details.dates[0].participants_start) }}
                  {{ formatTime(details.dates[0].participants_start) }}-{{ formatTime(details.dates[0].participants_end) }}
                </span>
                <span v-else-if="details.period">
                  <strong>Participants presence: </strong>{{ formatDayHuman(details.period.participants_start) }} {{ formatTime(details.period.participants_start) }}-{{ formatDayHuman(details.period.participants_end) }} {{ formatTime(details.period.participants_end) }}
                </span>
              </v-list-tile-title>
            </slot>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile v-if="details.location">
          <v-list-tile-avatar>
            <v-icon>mdi-bank</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <slot
              name="eventPopoverLocation"
              v-bind="slotData"
            >
              <v-list-tile-title>
                <span>{{ details.location }}</span>
              </v-list-tile-title>
            </slot>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile v-if="details.session.team">
          <v-list-tile-avatar>
            <v-icon>mdi-domain</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <slot
              name="eventPopoverLocation"
              v-bind="slotData"
            >
              <v-list-tile-title>
                <span>{{ details.session.team.name }}</span>
              </v-list-tile-title>
            </slot>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile v-if="details.session.topic || details.session.is_inclusive || details.session.is_online">
          <v-list-tile-avatar>
            <v-icon>mdi-tag</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>
              <div
                layout="row"
                layout-align="start center"
              >
                {{ details.session.topic.name }}
                <v-icon
                  v-if="details.session.topic.color"
                  :color="details.session.topic.color"
                  class="ml-2"
                >
                  mdi-circle
                </v-icon>
                <v-icon
                  v-if="details.session.is_inclusive"
                  title="Is inclusive"
                  color="primary"
                  class="ml-2"
                >
                  mdi-all-inclusive
                </v-icon>
                <v-icon
                  v-if="details.session.is_online"
                  title="Is online"
                  color="primary"
                  class="ml-2"
                >
                  mdi-cast-education
                </v-icon>
              </div>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile v-if="details.address">
          <v-list-tile-avatar>
            <v-icon>location_on</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <slot
              name="eventPopoverLocation"
              v-bind="slotData"
            >
              <v-list-tile-title>
                <span>{{ details.address }}</span>
              </v-list-tile-title>
            </slot>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile v-if="details.contact">
          <v-list-tile-avatar>
            <v-icon>mdi-account</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <slot
              name="eventPopoverDescription"
              v-bind="slotData"
            >
              <v-list-tile-title>
                <span>{{ details.contact }} <span v-if="details.session.contact && details.session.contact.phone">({{ details.session.contact.phone }})</span></span>
              </v-list-tile-title>
            </slot>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile v-if="details.session.referent_fullname || details.session.class_name || details.session.room || details.session.lang_id">
          <v-list-tile-avatar>
            <v-icon>mdi-school</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <slot
              name="eventPopoverDescription"
              v-bind="slotData"
            >
              <v-list-tile-title>
                <span v-if="details.session.referent_fullname">{{ details.session.referent_fullname }} - </span>{{ details.session.class_name }}
                <span v-if="details.session.room"> ({{ details.session.room }})</span>
                <span
                  v-if="details.session.lang_id"
                  class="font-weight-bold text-uppercase"
                >[{{ details.session.lang_id }}]</span>
              </v-list-tile-title>
            </slot>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile v-if="details.session.nb_participants">
          <v-list-tile-avatar>
            <v-icon>mdi-account-group</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>
              {{ details.session.nb_participants }} pupil{{ details.session.nb_participants > 1 ? 's': '' }}
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <div
          v-if="details.session.organization && details.session.organization.parking"
          layout="row"
          layout-align="start start"
          class="mx-4"
        >
          <v-icon class="mr-4">
            mdi-parking
          </v-icon>
          <span class="nl2br pt-1">{{ details.session.organization.parking }}</span>
        </div>
      </v-list>
      <div
        v-if="details.dates && details.dates.length > 1"
        layout="column"
        layout-align="start start"
        class="ml-4"
      >
        <span class="subheading font-weight-light">Dates:</span>
        <ul>
          <li
            v-for="d in details.dates"
            :key="d.id"
          >
            {{ formatDateHuman(d.start) }}, until {{ formatTime(d.end) }}
          </li>
        </ul>
      </div>
      <div
        v-if="details.trainers && details.trainers.length > 0"
        layout="column"
        layout-align="start start"
        class="ml-4"
      >
        <span class="subheading font-weight-light">Trainers:</span>
        <ul>
          <li
            v-for="t in details.trainers"
            :key="t.id"
          >
            {{ t.firstname }} {{ t.lastname }}
          </li>
        </ul>
      </div>
      <slot
        name="eventPopoverBodyBottom"
        v-bind="slotData"
      />
    </v-card-text>

    <slot
      name="eventPopoverActions"
      v-bind="slotData"
    />
  </v-card>
</template>

<script>
import DateMixin from "@/mixins/date";
import SessionsMixin from "@/mixins/sessions";
import Consts from "@/consts";

export default {
  mixins: [DateMixin, SessionsMixin],
  props: {
    calendarEvent: {
      required: true,
      type: Object
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      Consts
    };
  },
  computed: {
    slotData() {
      return {
        calendarEvent: this.calendarEvent,
        edit: this.edit,
        details: this.details,
        readOnly: this.readOnly
      };
    },
    classes() {
      return {
        "ds-event-cancelled": this.calendarEvent.cancelled
      };
    },
    styleHeader() {
      if (this.details.period) {
        return {
          color: this.details.forecolor,
          backgroundColor: this.details.period.period_type.color
        };
      } else {
        return {
          color: this.details.forecolor
        };
      }
    },
    styleButton() {
      return {
        color: this.details.forecolor
      };
    },
    details() {
      return this.calendarEvent.data;
    },
    isReadOnly() {
      return this.readOnly || this.$dayspan.readOnly || this.details.readonly;
    }
  },
  methods: {
    quit() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.ds-calendar-event-popover-card .v-btn--floating.v-btn--left {
  margin-left: 0px !important;
}

.ds-calendar-event-popover-card .v-btn--floating.v-btn--left.v-icon {
  height: auto;
}

.ds-calendar-event-popover-card .v-card__text {
  padding: 16px 0;
}

.ds-calendar-event-popover-card .v-card__text .v-list .v-list__tile {
  padding: 0px !important;
  height: auto;
}

.ds-calendar-event-popover-card .v-toolbar__extension {
  padding: 0 16px !important;
}

.ds-calendar-event-popover-card .v-toolbar__extension .v-toolbar__title {
  margin-left: 56px;
}
</style>
