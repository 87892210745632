<template>
  <div>
    <v-subheader>
      Description
    </v-subheader>
    <div layout="row">
      <div
        layout="column"
        flex
      >
        <v-subheader>
          Concept
        </v-subheader>
        <div
          layout="row"
          layout-align="start center"
        >
          <div
            v-if="session.topic"
            class="title font-weight-bold ml-3"
          >
            <span>{{ session.topic.name }}</span>
            <v-icon
              v-if="session.topic.color"
              :color="session.topic.color"
              class="ml-1"
            >
              mdi-circle
            </v-icon>
          </div>
          <v-icon
            v-if="session.is_inclusive"
            title="Is inclusive"
            color="primary"
            class="ml-2"
          >
            mdi-all-inclusive
          </v-icon>
          <v-icon
            v-if="session.is_online"
            title="Is online"
            color="primary"
            class="ml-2"
          >
            mdi-cast-education
          </v-icon>
        </div>
      </div>
      <div
        v-if="!isInternal && !isTraining"
        layout="column"
        flex
      >
        <v-subheader>
          Target group
        </v-subheader>
        <div
          v-if="session.target_group"
          class="title font-weight-bold ml-3"
        >
          {{ session.target_group.name }}
        </div>
      </div>
      <div
        v-if="!isInternal"
        layout="column"
        flex
      >
        <v-subheader>
          Lang
        </v-subheader>
        <div class="title font-weight-bold ml-3 text-uppercase">
          [{{ session.lang_id ? session.lang_id : '?' }}]
        </div>
      </div>
    </div>
    <div
      v-if="!isInternal"
      layout="row"
    >
      <div
        v-if="!isFreelances"
        layout="column"
        flex
      >
        <v-subheader>
          Room
        </v-subheader>
        <div class="title font-weight-bold ml-3">
          {{ session.room }}
        </div>
      </div>
      <div
        v-else
        layout="column"
        flex
      >
        <v-subheader>
          Team
        </v-subheader>
        <div class="title font-weight-bold ml-3">
          {{ session.team.name }}
        </div>
      </div>
      <div
        layout="column"
        flex
      >
        <v-subheader>
          Participants
        </v-subheader>
        <div class="title font-weight-bold ml-3">
          {{ session.nb_participants }}
        </div>
      </div>
      <div
        v-if="isActivity"
        layout="column"
        flex
      >
        <v-subheader>
          Accompanying persons
        </v-subheader>
        <div class="title font-weight-bold ml-3">
          {{ session.nb_accompanying }}
        </div>
      </div>
      <div
        v-else-if="session.session_type === Consts.TEACHERS"
        layout="column"
        flex
      >
        <v-subheader>
          Partner
        </v-subheader>
        <div class="title font-weight-bold ml-3">
          {{ session.referent_fullname }}
        </div>
      </div>
      <div
        v-else-if="session.session_type === Consts.SCHOOL"
        layout="column"
        flex
      >
        <v-subheader>
          Teacher
        </v-subheader>
        <div class="title font-weight-bold ml-3">
          {{ session.referent_fullname }}
        </div>
      </div>
      <div
        v-else-if="session.session_type === Consts.YOUTH"
        layout="column"
        flex
      >
        <v-subheader>
          Educator
        </v-subheader>
        <div class="title font-weight-bold ml-3">
          {{ session.referent_fullname }}
        </div>
      </div>
      <div
        v-else
        layout="column"
        flex
      />
    </div>
    <div
      v-if="isFreelances"
      layout="row"
    >
      <div
        layout="column"
        flex
      >
        <v-subheader>
          Season
        </v-subheader>
        <div class="title font-weight-bold ml-3">
          {{ formatDate(session.season.start) }} - {{ formatDate(session.season.end) }}
        </div>
      </div>
    </div>
    <div
      v-if="isFreelances"
      layout="row"
    >
      <div
        layout="column"
        flex
      >
        <v-subheader>
          Instructions for specialized animators
        </v-subheader>
        <div class="font-weight-light ml-3">
          <div style="white-space: pre-wrap;        word-wrap: break-word;font-family: inherit;">
            {{ session.information ? session.information : '-' }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="session.session_type === Consts.EVENTS || session.session_type === Consts.OTHER"
      layout="row"
    >
      <div
        layout="column"
        flex
      >
        <v-subheader>
          Objectives
        </v-subheader>
        <div class="font-weight-light ml-3">
          <div style="white-space: pre-wrap;        word-wrap: break-word;font-family: inherit;">
            {{ session.objectives }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isEvent"
      layout="row"
    >
      <div
        layout="column"
        flex
      >
        <v-subheader>
          Partner
        </v-subheader>
        <div class="font-weight-light ml-3">
          <div style="white-space: pre-wrap;        word-wrap: break-word;font-family: inherit;">
            {{ session.partner ? session.partner : "no info" }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isEvent"
      layout="row"
    >
      <div
        layout="column"
        flex
      >
        <v-subheader>
          Press & Media
        </v-subheader>
        <div class="font-weight-light ml-3">
          <div style="white-space: pre-wrap;        word-wrap: break-word;font-family: inherit;">
            {{ session.press_media ? session.press_media : "no info" }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isEvent"
      layout="row"
    >
      <div
        layout="column"
        flex
      >
        <v-subheader>
          Website news
        </v-subheader>
        <div class="font-weight-light ml-3">
          <div style="white-space: pre-wrap;        word-wrap: break-word;font-family: inherit;">
            {{ session.website_news ? session.website_news : "no info" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dateMixin from '@/mixins/date'
import Consts from '@/consts'
export default {
	mixins: [dateMixin],
	props: {
		session: {
			type: Object,
			required: true,
			default: () => {}
		}
  },
  data() {
    return {
      Consts
    }
  },
	computed: {
		isInternal () {
      if (parseInt(this.session.session_type) == Consts.INTERNAL) {
        return true
      }
      return false
    },
    isTraining() {
      if (parseInt(this.session.session_type) == Consts.FREELANCES_TRAINING) {
        return true;
      }
      return false;
		},
		isActivity() {
      if (parseInt(this.session.session_type) == Consts.ACTIVITY) {
        return true;
      }
      return false;
    },
		isFreelances() {
      if (this.session.application == Consts.APPLICATION.FREELANCES) {
        return true;
      }

      return false;
		},
		isEvent () {
			if (this.session.session_type == Consts.EVENTS) {
        return true
			}

			return false
		}
	},
}
</script>
<style scoped>
	.v-subheader {
		padding: 0 10px;
	}
</style>
