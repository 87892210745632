<template>
  <div
    layout-align="center center"
    class="mb-6"
  >
    <div class="page-topborder" />

    <div
      v-if="user.id"
      class="page-width pb-3"
    >
      <div
        class="py-3"
      >
        <div
          class="headline font-weight-bold"
          layout="row"
          layout-align="start center"
        >
          <v-icon
            class="ml-2"
            :size="32"
            :color="user.is_enabled ? 'success' : 'error'"
            :title="user.is_enabled ? 'Enabled' : 'Disabled'"
          >
            mdi-circle-outline
          </v-icon>
          <span class="ml-2">{{ user.firstname }} {{ user.lastname }}</span>
        </div>
      </div>
      <router-link
        :to="{name: prevRoute}"
        class="link-unstyled py-2 ml-4 no-printable"
        style="width: max-content"
      >
        <v-icon
          color="primary"
          size="16"
        >
          mdi-arrow-left
        </v-icon>
        Back to the list
      </router-link>
      <div
        layout="row"
        layout-align="start start"
        class="mb-3 no-printable"
        style="background-color: #fff"
      >
        <v-tabs
          class="ml-3 grey"
          flex
        >
          <v-tabs-slider color="#f4b944" />
          <v-tab
            router-link
            :to="{name: 'admin-settings-users-show-info', params: {id: user.id}}"
          >
            Information
          </v-tab>
          <v-tab
            router-link
            :to="{name: 'admin-settings-users-show-contracts', params: {id: user.id}}"
          >
            Contracts
          </v-tab>
          <v-tab
            router-link
            :to="{name: 'admin-settings-users-show-skills', params: {id: user.id}}"
          >
            Skills
          </v-tab>
          <v-tab
            router-link
            :to="{name: 'admin-settings-users-show-historic', params: {id: user.id}}"
          >
            Historic
          </v-tab>
        </v-tabs>

        <v-btn
          class="mx-0"
          large
          icon
          :disabled="Auth.user.id == user.id"
          @click="toggle"
        >
          <v-icon>{{ user.is_enabled ? 'mdi-lock': 'mdi-lock-open' }}</v-icon>
        </v-btn>
        <v-btn
          v-if="user.is_candidate"
          large
          class="mx-0"
          icon
          title="Transform the candidate to a real trainer"
          @click="transformCandidate"
        >
          <v-icon color="green">
            mdi-account-check
          </v-icon>
        </v-btn>
      </div>
      <router-view
        :key="routerViewKey"
        :user.sync="user"
        class="px-3"
      />
    </div>
    <div
      v-else
      layout="row"
      class="ma-5"
      layout-align="center center"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      />
    </div>
  </div>
</template>

<script>
import Auth from "@/plugins/auth";
import Consts from "@/consts";
import GlobalMixin from "@/mixins/global";
import Params from "@/api/params";

export default {
  mixins: [GlobalMixin],
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === "admin-settings-users-contracts") {
        vm.prevRoute = from.name.slice(0);
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (from.params.id !== to.params.id) {
      this.fetch(to.params.id); // Prevent tab change
    }
    next();
  },
  data() {
    return {
      loading: false,
      user: {
        groups: []
      },
      routerViewKey: 1,
      Consts,
      Auth,
      prevRoute: "admin-settings-users-index"
    };
  },

  mounted() {
    this.loading = true;
    this.fetch();
  },

  methods: {
    fetch(id = null) {
      Params.get("users", id ? id : this.$route.params.id).then(res => {
        this.user = res.data;
        if (this.user && this.user.groups && this.user.groups.length > 0) {
          let arr = [];
          for (let i = 0; i < this.user.groups.length; i++) {
            arr.push(this.user.groups[i].id);
          }
          this.user.groups = arr;
        }
        this.loading = false;
        this.routerViewKey++;
        this.user = Object.assign({}, this.user);
      });
    },
    transformCandidate() {
      Params.update("users", this.user.id, { is_candidate: 0 }).then(() => {
        this.user.is_candidate = 0;
        this.$snotify.success(`User has been transformed to a real trainer`);
        this.fetch();
      });
    },
    toggle() {
      if (this.user.is_enabled) {
        Params.disable("users", this.user.id).then(() => {
          this.user.is_enabled = 0;
          this.$snotify.success(
            `User "${this.user.firstname} ${
              this.user.lastname
            }" has been disabled`
          );
        });
      } else {
        Params.enable("users", this.user.id).then(() => {
          this.user.is_enabled = 1;
          this.$snotify.success(
            `User "${this.user.firstname} ${
              this.user.lastname
            }" has been enabled`
          );
        });
      }
    }
  }
};
</script>
<style scoped>
.expo {
  vertical-align: super;
  color: lightgrey;
  font-size: 14px;
}
.edit-icon {
  text-decoration: none;
}

@media print {
  .headline > .v-btn,
  .headline > .v-menu--inline,
  .v-tabs {
    display: none;
  }
  .no-printable {
    display: none;
  }
}
</style>
<style>
@media print {
  .no-printable {
    display: none;
  }
}
</style>
