<template>
  <div>
    <div
      layout="column"
      style="padding-left: 0"
    >
      <v-subheader>
        Groups
      </v-subheader>
      <div
        layout-gt-md="row"
        layout-align="start stretch"
        layout-wrap
        flex
        style="padding-left: 15px"
      >
        <template v-for="t in groups">
          <div
            v-if="t.users.length > 0"
            :key="t.id"
          >
            <div class="subheading font-weight-light">
              {{ t.name }}
            </div>
            <v-card
              class="mb-2"
              flat
            >
              <v-card-text>
                <template v-for="u in t.users">
                  <v-checkbox
                    v-if="members[u.id]"
                    :key="t.id + u.id"
                    v-model="members[u.id]"
                    flex="50"
                    hide-details
                    class="py-1 my-1"
                    :label="`${u.firstname} ${u.lastname}`"
                    disabled
                  />
                </template>
              </v-card-text>
            </v-card>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
	props: {
		session: {
			type: Object,
			required: true,
			default: () => {}
		},
		groups: {
			type: Array,
			required: false,
			default: () => []
		}
	},
	computed: {
		members: {
      get () {
        let out = {}

        for (let i = 0; i < this.session.users_groups.length; ++i) {
          const ut = this.session.users_groups[i]
          out[ut] = true
        }

        return out
      },
      set (x) {
        this.session.users_groups = []

        for (let i in x) {
          this.session.users_groups.push(i)
        }
      }
    },
	},
}
</script>
<style scoped>
	.v-subheader {
		padding: 0 10px;
	}
</style>
