<template>
  <div>
    <div class="title ma-3">
      Add multiple sessions
    </div>

    <router-link
      :to="{name: 'sessions-index'}"
      class="link-unstyled mx-2 my-3"
      style="width: max-content"
    >
      <v-icon
        color="primary"
        size="16"
      >
        mdi-arrow-left
      </v-icon>
      Back to the list
    </router-link>

    <div
      v-if="loading"
      class="ma-5 text-xs-center"
    >
      <v-progress-circular
        indeterminate
        :size="32"
        color="accent"
      />
    </div>

    <div v-else>
      <v-form
        v-if="newItem !== {}"
        ref="form"
        layout="column"
        flex="100"
        class="mx-2 my-3 pa-1 elevation-1 white ams-form"
      >
        <v-layout
          row
          wrap
          class="mt-2"
        >
          <v-flex
            xs12
            sm6
            md2
            class="grid-spacing"
            @keydown.tab="onTab"
          >
            <v-autocomplete
              v-model="newItem.organization"
              label="Organization *"
              item-text="name"
              item-value="id"
              :loading="newItem.loadingOrg"
              :rules="[ v => !!v || 'This field is required' ]"
              tabindex="1"
              :items="newItem.organizations"
              hide-no-data
              return-object
              :filter="() => true"
              clearable
              no-filter
              prepend-inner-icon="mdi-magnify"
              :search-input.sync="newItem.orga_input"
              :allow-overflow="false"
              attach
              :error="onOrgaChange()"
              @input="onSelectOrg"
            >
              <template
                slot="item"
                slot-scope="{ item }"
              >
                <div
                  v-if="item._isNew"
                  layout-align="start center"
                  layout="row"
                >
                  <v-icon
                    color="primary"
                    class="mr-3"
                  >
                    mdi-plus-box
                  </v-icon>
                  Create a new organization
                </div>
                <div
                  v-else
                  style="max-height: 100%; overflow-y: hidden"
                >
                  {{ item.name }}
                </div>
              </template>
              <template
                slot="selection"
                slot-scope="{ item }"
              >
                {{ item.name }}
              </template>
            </v-autocomplete>
          </v-flex>
          <v-flex
            xs12
            sm6
            md2
            class="grid-spacing"
            @keydown.tab="onTab($event, 102)"
          >
            <v-select
              v-model="newItem.contact"
              :disabled="!newItem.organization_id"
              label="Contact *"
              :loading="loading"
              :rules="[ v => !!v || 'This field is required' ]"
              :item-text="fullname"
              item-value="id"
              :items="newItem.contacts"
              return-object
              tabindex="2"
              @input="onSelectContact(newItem.contact, 1)"
            >
              <template slot="prepend">
                <v-icon
                  right
                  color="primary"
                  title="Create a new contact"
                  @click="newContact('contact_id', 'contact')"
                  @keyup.enter="newContact('contact_id', 'contact')"
                >
                  mdi-plus-circle
                </v-icon>
              </template>
            </v-select>
          </v-flex>
        </v-layout>
        <v-layout
          row
          wrap
          class="mt-2"
        >
          <div>
            <v-layout
              v-for="(d,i) in newItem.dates"
              :key="'date_layout_' + i"
              row
              wrap
            >
              <div>
                <div
                  layout="row"
                  layout-align="start center"
                  style="width: 175px"
                >
                  <v-icon
                    v-if="i === 0"
                    color="primary"
                    style="position: absolute; margin-top: -12px"
                    @click="newDate()"
                    @keyup.enter="newDate()"
                  >
                    mdi-plus-circle
                  </v-icon>
                  <v-icon
                    v-else
                    color="error"
                    style="position: absolute; margin-top: -12px"
                    @click="deleteDate(i)"
                    @keyup.enter="deleteDate(i)"
                  >
                    mdi-close-circle
                  </v-icon>
                  <v-text-field
                    v-model="d.date_start_text"
                    label="On *"
                    :rules="dateRules"
                    :tabindex="(i+1) + '02'"
                    class="ml-4"
                    @blur="d.date_start = parseDate(d.date_start_text)"
                  >
                    <template slot="prepend">
                      <v-menu
                        :ref="'date_start_'+i"
                        :close-on-content-click="false"
                        :return-value.sync="d.date_start"
                        transition="scale-transition"
                        offset-y
                        full-width
                        :nudge-right="40"
                        lazy
                      >
                        <v-icon slot="activator">
                          event
                        </v-icon>
                        <v-date-picker
                          v-model="d.date_start"
                          first-day-of-week="1"
                          @input="saveDateStart(d.date_start, i); d.date_start_text = americanizeDate(d.date_start)"
                        />
                      </v-menu>
                    </template>
                  </v-text-field>
                </div>
              </div>
              <div>
                <div
                  layout="row"
                  layout-align="start center"
                  class="ml-5"
                  style="width: 200px"
                >
                  <v-text-field
                    v-model="d.clock_start_hour"
                    :rules="hourRules"
                    :tabindex="(i+1) + '03'"
                    label="from (work) *"
                    type="number"
                    min="00"
                    max="23"
                    maxlength="2"
                    minlength="2"
                    @blur="d.clock_start = d.clock_start_hour + ':' + d.clock_start_min"
                    @change="updateDuration(d)"
                  />

                  <span
                    class="mx-3"
                    style="margin-bottom: 12px"
                  >:</span>
                  <v-text-field
                    v-model="d.clock_start_min"
                    :tabindex="(i+1) + '04'"
                    :rules="minRules"
                    type="number"
                    min="00"
                    max="59"
                    maxlength="2"
                    minlength="2"
                    @blur="d.clock_start = d.clock_start_hour + ':' + d.clock_start_min"
                    @change="updateDuration(d)"
                  />
                </div>
              </div>
              <div>
                <div
                  layout="row"
                  layout-align="start center"
                  class="ml-5"
                  style="width: 200px"
                >
                  <v-text-field
                    v-model="d.clock_end_hour"
                    :rules="hourRules"
                    :tabindex="(i+1) + '05'"
                    label="to (work) *"
                    type="number"
                    min="00"
                    max="23"
                    maxlength="2"
                    minlength="2"
                    @blur="d.clock_end = d.clock_end_hour + ':' + d.clock_end_min"
                    @change="updateDuration(d)"
                  />

                  <span
                    class="mx-3"
                    style="margin-bottom: 12px"
                  >:</span>
                  <v-text-field
                    v-model="d.clock_end_min"
                    :tabindex="(i+1) + '06'"
                    :rules="minRules"
                    type="number"
                    min="00"
                    max="59"
                    maxlength="2"
                    minlength="2"
                    @blur="d.clock_end = d.clock_end_hour + ':' + d.clock_end_min"
                    @change="updateDuration(d)"
                  />
                </div>
              </div>
               <div
                  layout="row"
                  layout-align="start center"
                  class="ml-5"
                  style="width: 200px"
                >
                  <v-text-field
                    v-model="d.total_time"
                    label="Work duration (decimals hours)"
                    class="ml-2"
                    hide-details
                    :tabindex="(i+1) + '06'"
                  />
                </div>
              <div>
                <div
                  layout="row"
                  layout-align="start center"
                  class="ml-5"
                  style="width: 200px"
                >
                  <v-text-field
                    v-model="d.clock_participants_start_hour"
                    :rules="hourRules"
                    :tabindex="(i+1) + '07'"
                    label="from (participants) *"
                    type="number"
                    min="00"
                    max="23"
                    maxlength="2"
                    minlength="2"
                    @blur="d.clock_participants_start = d.clock_participants_start_hour + ':' + d.clock_participants_start_min"
                  >
                    <!--<template slot="prepend">
                      <v-menu
                        :ref="'clock_start_'+i"
                        :close-on-content-click="false"
                        :return-value.sync="d.clock_start"
                        :nudge-right="40"
                        lazy
                        min-width="290px"
                      >
                        <v-icon slot="activator">access_time</v-icon>
                        <v-time-picker
                          format="24hr"
                          v-model="d.clock_start"
                          @input="saveClockStart(d.clock_start, i); d = parseTime(d)"
                        ></v-time-picker>
                      </v-menu>
                    </template>-->
                  </v-text-field>
                  <span
                    class="mx-3"
                    style="margin-bottom: 12px"
                  >:</span>
                  <v-text-field
                    v-model="d.clock_participants_start_min"
                    :tabindex="(i+1) + '08'"
                    :rules="minRules"
                    type="number"
                    min="00"
                    max="59"
                    maxlength="2"
                    minlength="2"
                    @blur="d.clock_participants_start = d.clock_participants_start_hour + ':' + d.clock_participants_start_min"
                  />
                </div>
              </div>
              <div>
                <div
                  layout="row"
                  layout-align="start center"
                  class="ml-5"
                  style="width: 200px"
                >
                  <v-text-field
                    v-model="d.clock_participants_end_hour"
                    :rules="hourRules"
                    :tabindex="(i+1) + '09'"
                    label="to (participants) *"
                    type="number"
                    min="00"
                    max="23"
                    maxlength="2"
                    minlength="2"
                    @blur="d.clock_participants_end = d.clock_participants_end_hour + ':' + d.clock_participants_end_min"
                  />
                  <span
                    class="mx-3"
                    style="margin-bottom: 12px"
                  >:</span>
                  <v-text-field
                    v-model="d.clock_participants_end_min"
                    :tabindex="(i+1) + '10'"
                    :rules="minRules"
                    type="number"
                    min="00"
                    max="59"
                    maxlength="2"
                    minlength="2"
                    @blur="d.clock_participants_end = d.clock_participants_end_hour + ':' + d.clock_participants_end_min"
                  />
                </div>
              </div>
              <div
                @keydown.tab="onTab"
                class="ml-5"
              >
                <v-select
                  v-model="d.trainers"
                  :tabindex="(i+1) + '11'"
                  label="Trainers"
                  :loading="loading"
                  :items="trainers"
                  multiple
                  item-value="id"
                  :item-text="fullname"
                />
              </div>
            </v-layout>
          </div>
        </v-layout>

        <v-layout
          row
          wrap
        >
          <v-flex
            xs12
            sm6
            md2
            class="grid-spacing"
            @keydown.tab="onTab"
          >
            <v-select
              v-model="newItem.target_group_id"
              tabindex="10001"
              label="Target group"
              :loading="loading"
              :items="targetGroups"
              item-text="name"
              item-value="id"
            />
          </v-flex>
          <v-flex
            xs12
            sm6
            md2
            class="grid-spacing"
          >
            <v-text-field
              v-model="newItem.class_name"
              label="Class name"
              tabindex="10002"
            />
          </v-flex>
          <v-flex
            xs12
            sm6
            md2
            class="grid-spacing"
          >
            <v-text-field
              v-model="newItem.nb_participants"
              tabindex="10003"
              label="Nb of participants"
              type="number"
            />
          </v-flex>
          <v-flex
            xs12
            sm6
            md2
            class="grid-spacing"
          >
            <v-text-field
              v-model="newItem.room"
              label="Room"
              tabindex="10004"
            />
          </v-flex>
          <v-flex
            xs12
            sm6
            md2
            class="grid-spacing"
            @keydown.tab="onTab"
          >
            <v-select
              v-model="newItem.lang_id"
              tabindex="10005"
              label="Lang"
              :loading="loading"
              :items="langs"
              :item-text="(i) => {return i.id.toUpperCase()}"
              item-value="id"
            />
          </v-flex>
          <v-flex
            xs12
            sm6
            md2
            class="grid-spacing"
          >
            <v-text-field
              ref="teacher_fullname"
              v-model="newItem.referent_fullname"
              tabindex="10006"
              label="Teacher fullname"
            />
          </v-flex>
          <v-flex
            xs12
            sm6
            md2
            class="grid-spacing"
          >
            <v-text-field
              v-model="newItem.location"
              tabindex="10007"
              label="Location"
            />
          </v-flex>
          <v-flex
            xs12
            sm6
            md2
            class="grid-spacing"
          >
            <v-text-field
              v-model="newItem.comments"
              tabindex="10008"
              label="Comment"
            />
          </v-flex>
          <v-flex
            xs12
            sm6
            md2
            class="grid-spacing"
          >
            <v-select
              v-model="newItem.topic_id"
              tabindex="10009"
              label="Concept *"
              :loading="loading"
              :items="topics"
              item-text="name"
              item-value="id"
              :rules="[ v => !!v || 'This field is required' ]"
            />
          </v-flex>
          <v-flex
            xs12
            sm6
            md2
            class="grid-spacing"
          >
            <v-text-field
              v-model="newItem.ticket_number"
              tabindex="10010"
              label="Ticket number"
              type="number"
              :hint="ticketText"
              @input="existingTicket"
            />
          </v-flex>
          <v-flex
            xs12
            sm6
            md3
            class="grid-spacing"
          >
            <v-layout row>
              <div class="ml-1">
                <v-icon title="Video-projector">
                  mdi-projector
                </v-icon>
                <v-checkbox
                  id="checkbox_10010"
                  v-model="newItem.beamer"
                  class="setup-cb mt-0 pa-0"
                  hide-details
                />
              </div>
              <div class="ml-1">
                <v-icon title="Projection screen">
                  mdi-projector-screen
                </v-icon>
                <v-checkbox
                  id="checkbox_10011"
                  v-model="newItem.screen"
                  class="setup-cb mt-0 pa-0"
                  hide-details
                />
              </div>
              <div class="ml-1">
                <v-icon title="Speakers">
                  mdi-speaker-wireless
                </v-icon>
                <v-checkbox
                  id="checkbox_10012"
                  v-model="newItem.loudspeak"
                  class="setup-cb mt-0 pa-0"
                  hide-details
                />
              </div>
              <div class="ml-1">
                <v-icon title="Internet connection">
                  mdi-web
                </v-icon>
                <v-checkbox
                  id="checkbox_10013"
                  v-model="newItem.internet"
                  class="setup-cb mt-0 pa-0"
                  hide-details
                />
              </div>
              <div class="ml-1">
                <v-icon title="Power plug">
                  mdi-power-plug
                </v-icon>
                <v-checkbox
                  id="checkbox_10014"
                  v-model="newItem.socket"
                  class="setup-cb mt-0 pa-0"
                  hide-details
                />
              </div>
              <div class="ml-1">
                <v-icon title="USB stick">
                  mdi-usb
                </v-icon>
                <v-checkbox
                  id="checkbox_10015"
                  v-model="newItem.usb"
                  class="setup-cb mt-0 pa-0"
                  hide-details
                />
              </div>
              <div class="ml-3">
                <v-icon
                  title="Is online"
                  color="primary"
                >
                  mdi-cast-education
                </v-icon>
                <v-checkbox
                  id="checkbox_10016"
                  v-model="newItem.is_online"
                  class="setup-cb mt-0 pa-0"
                  hide-details
                />
              </div>
              <div class="ml-1">
                <v-icon
                  title="Is inclusive"
                  color="primary"
                >
                  mdi-all-inclusive
                </v-icon>
                <v-checkbox
                  id="checkbox_10017"
                  v-model="newItem.is_inclusive"
                  class="setup-cb mt-0 pa-0"
                  hide-details
                />
              </div>
            </v-layout>
          </v-flex>
          <v-flex class="grid-spacing px-0 text-xs-right">
            <v-btn
              color="success"
              class="ml-1 focus-btn"
              tabindex="10017"
              @click="validSession"
            >
              <v-icon class="mr-2">
                mdi-check
              </v-icon> Validate
            </v-btn>
            <v-btn
              color="error"
              class="ml-1"
              tabindex="10018"
              @click="resetForm()"
              @keyup.enter="resetForm()"
            >
              <v-icon class="mr-2">
                mdi-close
              </v-icon> Reset
            </v-btn>
          </v-flex>
        </v-layout>
      </v-form>

      <template v-if="sessions.length">
        <v-data-table
          :items="sessions"
          :headers="headers"
          hide-actions
          class="mx-2 my-3 elevation-1"
        >
          <tr
            slot="items"
            slot-scope="props"
          >
            <td>#{{ nextRef + props.index }}</td>
            <td>
              <div
                v-for="(d,i) in props.item.dates"
                :key="props.index + '_date_' + i"
              >
                {{ props.item.dates[i].date_start_text }}, {{ props.item.dates[i].clock_start }}-{{ props.item.dates[i].clock_end }}
              </div>
            </td>
            <td>{{ props.item.organization ? props.item.organization.name : "" }}</td>
            <td>
              <div
                layout="row"
                layout-align="start center"
              >
                <span
                  v-if="props.item.class_name || props.item.room"
                  class="mr-1"
                >
                  {{ props.item.class_name }}
                  <template v-if="props.item.class_name && props.item.room"> / </template>
                  {{ props.item.room }}
                </span>
                <span
                  v-if="props.item.lang_id"
                  class="font-weight-bold text-uppercase"
                >[{{ props.item.lang_id }}]</span>
                <v-icon
                  v-if="!props.item.beamer"
                  class="no-printable"
                >
                  mdi-projector
                </v-icon>
                <v-icon
                  v-if="!props.item.screen"
                  class="no-printable"
                >
                  mdi-projector-screen
                </v-icon>
                <v-icon
                  v-if="!props.item.loudspeak"
                  class="no-printable"
                >
                  mdi-speaker-wireless
                </v-icon>
                <v-icon
                  v-if="!props.item.internet"
                  class="no-printable"
                >
                  mdi-web
                </v-icon>
                <v-icon
                  v-if="!props.item.socket"
                  class="no-printable"
                >
                  mdi-power-plug
                </v-icon>
                <v-icon
                  v-if="!props.item.usb"
                  class="no-printable"
                >
                  mdi-usb
                </v-icon>
              </div>
            </td>
            <td>{{ targetGroupsById[props.item.target_group_id] ? targetGroupsById[props.item.target_group_id].name : '' }}</td>
            <td>{{ props.item.nb_participants }}</td>
            <td>{{ props.item.contact ? props.item.contact.firstname + " " + props.item.contact.lastname : "" }}</td>
            <td>
              <div
                v-for="(t, ti) in getTrainers(props.item)"
                :key="'trainer_' + ti"
              >
                {{ t }}
              </div>
            </td>
            <td>
              <div
                layout="row"
                layout-align="start center"
              >
                <div class="mr-1">
                  {{ topicsById[props.item.topic_id] ? topicsById[props.item.topic_id].name : '' }}
                </div>
                <v-icon
                  v-if="props.item.is_online"
                  class="no-printable"
                  color="primary"
                  title="Is online"
                >
                  mdi-cast-education
                </v-icon>
                <v-icon
                  v-if="props.item.is_inclusive"
                  class="no-printable"
                  color="primary"
                  title="Is inclusive"
                >
                  mdi-all-inclusive
                </v-icon>
              </div>
            </td>
            <td>
              <v-avatar
                :title="sessionStatusStrLong(props.item.status)"
                :color="sessionStatusColor(props.item.status)"
                :size="32"
                class="black--text font-weight-medium"
              >
                {{ sessionStatusStr(props.item.status) }}
              </v-avatar>
            </td>
            <td>
              <v-icon
                right
                @click="duplicateSession(props.index)"
              >
                mdi-content-duplicate
              </v-icon>
              <v-icon
                right
                color="red"
                @click="removeSession(props.index)"
              >
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </v-data-table>

        <div
          layout="row"
          layout-align="end center"
          class="mx-2 my-3"
        >
          <v-menu
            v-model="modal"
            :close-on-content-click="false"
          >
            <v-btn
              v-if="sessions && sessions.length > 1"
              slot="activator"
              :disabled="!canCreate"
              depressed
              color="primary"
              class="my-0"
            >
              Create {{ sessions.length }} sessions
            </v-btn>
            <v-btn
              v-else
              slot="activator"
              depressed
              color="primary"
              :disabled="!sessions || !sessions.length || !canCreate"
              class="my-0"
            >
              Create session
            </v-btn>
            <v-card style="max-width: 450px">
              <v-card-title class="title">
                Save session{{ (sessions && sessions.length > 1) ? 's' : '' }}
              </v-card-title>
              <v-card-text>
                <span v-if="sessions && sessions.length > 1">
                  Are you sure you want to create these sessions?
                </span>
                <span v-else>
                  Are you sure you want to create this session?
                </span>
              </v-card-text>
              <v-card-actions
                layout="row"
                layout-align="end center"
              >
                <v-btn
                  depressed
                  color="error"
                  @click="modal = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  depressed
                  color="success"
                  @click="save(false)"
                >
                  Yes without notifying
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  @click="save(true)"
                >
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          or
          <router-link
            :to="{name: 'sessions-index'}"
            class="ml-2 black--text"
          >
            Cancel
          </router-link>
        </div>
      </template>
      <div
        v-else
        class="white elevation-1 mx-2 my-3 pa-2 text-xs-center font-italic grey--text text--darken-2"
      >
        No session in the list
      </div>
    </div>

    <contact-dialog
      :visible.sync="contactDialog"
      :organization="currentOrg"
      @created="onContactCreated"
    />
    <organization-dialog
      :visible.sync="organizationDialog"
      @created="onOrgCreated"
    />
  </div>
</template>

<script>
import Sessions from '@/api/sessions'
import Params from '@/api/params'
import IsoLangs from '@/api/isolangs'
import Organizations from '@/api/organizations'
import FormDataMixin from '@/mixins/formdata'
import SessionsMixin from '@/mixins/sessions'
import DatesMixin from '@/mixins/date'
import OrganizationDialog from "@/components/shared/OrganizationDialog.vue"
import ContactDialog from "@/components/shared/ContactDialog.vue"
import Consts from '@/consts'
import moment from 'moment'
import axios from 'axios'

export default {
  components: { OrganizationDialog, ContactDialog },
  mixins: [FormDataMixin, SessionsMixin, DatesMixin],
  data () {
    return {
      headers: [
        { text: '#', sortable: false },
        { text: 'Date(s)', sortable: false },
        { text: 'Organization', sortable: false },
        { text: 'Class / Room', sortable: false },
        { text: 'Target group', sortable: false },
        { text: 'Pupils', sortable: false },
        { text: 'Contact(s)', sortable: false },
        { text: 'Trainer(s)', sortable: false },
        { text: 'Concept', sortable: false },
        { text: 'Status', sortable: false },
        { text: 'Actions', sortable: false }
      ],
      newItem: {},
      sessions: [],
      organizations: [],
      currentSess: null,
      currentOrg: null,
      organizationsList: [],
      loading: false,
      topics: [],
      targetGroups: [],
      groups: [],
      langs: [],
      trainers: [],
      contactDialog: false,
      newContactField: null,
      newContactObj: null,
      currentInput: null,
      modal: false,
      nextRef: null,
      hourRules: [
        v => !!v || 'Hour is required',
        v => (v && v.length == 2) || 'Invalid hour',
        v => (v && v >= 0 && v <= 23) || 'Invalid hour',
      ],
      minRules: [
        v => !!v || 'Minutes are required',
        v => (v && v.length == 2) || 'Invalid time',
        v => (v && v >= 0 && v <= 59) || 'Invalid time'
      ],
      dateRules: [
        v => !!v || 'Date is required',
        v => (v && moment(v, 'DD.MM.YYYY').isValid()) || 'Invalid time'
      ],
      source: null,
      organizationDialog: false,
      ticketText: '',
    }
  },

  computed: {
    canCreate () {
      return this.sessions.length
    },
    trainersById () {
      let out = {}
      this.trainers.forEach(t => {
        out[t.id] = t
      })
      return out
    },
    topicsById () {
      let out = {}
      this.topics.forEach(t => {
        out[t.id] = t
      })
      return out
    },
    targetGroupsById () {
      let out = {}
      this.targetGroups.forEach(tg => {
        out[tg.id] = tg
      })
      return out
    }
  },

  mounted () {
    this.fetchParams()
    this.newSession()
    this.fetchNextReference()
  },

  updated () {
    [10010, 10011, 10012, 10013, 10014, 10015, 10016, 10017].forEach(i => {
      let el = document.getElementById('checkbox_' + i)
      if (el) {
        el.setAttribute('tabindex', i)
      }
    })
  },

  methods: {
    existingTicket () {
      Sessions.existingTicket(this.newItem).then((res) => {
        if (res.data && res.data != 0) {
          this.ticketText = 'A session with this ticket number already exusts'
        } else {
          this.ticketText = ''
        }
      })
    },
    getTrainers (s) {
      let res = {}
      for (let i = 0; i < s.dates.length; i++) {
        const d = s.dates[i]
        if (d.trainers) {
          for (let j = 0; j < d.trainers.length; j++) {
            const id = d.trainers[j]
            if (!res[id]) {
              res[id] = this.fullname(this.trainersById[id])
            }
          }
        }
      }

      return Object.values(res)
    },
    fetchNextReference () {
      Sessions.nextReference().then(res => {
        this.nextRef = res.data
      })
    },
    onTab (elem, t = null) {
      if (elem.shiftKey) {
        return
      }

      let index = elem.target.attributes.tabindex.value

      if (!index) {
        return
      }

      let target = null

      if (t) {
        target = parseInt(t)
      } else {
        target = parseInt(index) + 1
      }

      let elems = document.querySelectorAll('[tabindex="' + target + '"]')

      if (elems.length != 1) {
        return
      }

      this.$nextTick(() => {
        elems[0].focus()
      })

      elem.preventDefault()
      elem.stopPropagation()
    },
    americanizeDate (date) {
      if (!date) return null

      if (date.indexOf('.') >= 0) {
        return date
      }

      return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')
    },
    parseDate (date) {
      if (!date) return null

      if (date.indexOf('-') >= 0) {
        return date
      }

      return moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD')
    },
    save (toSend = true) {
      this.modal = false

      const sess = JSON.parse(JSON.stringify(this.sessions))
      for (let i = 0; i < sess.length; i++) {
        let dates = [],
          trainers = {}
        for (let j = 0; j < sess[i].dates.length; j++) {
          const d = sess[i].dates[j]
          d.start = d.date_start + ' ' + d.clock_start
          d.end = d.date_start + ' ' + d.clock_end
          d.participants_start = d.date_start + ' ' + d.clock_participants_start
          d.participants_end = d.date_start + ' ' + d.clock_participants_end
          d.id = this.uuid()

          if (d.trainers) {
            d.trainers.forEach(t => {
              if (!trainers.hasOwnProperty(t)) {
                trainers[t] = {
                  user_id: t,
                  dates: {}
                }
              }
              trainers[t].dates[d.id] = true
            })
          }

          dates.push(d)
        }
        sess[i].dates = dates
        sess[i].trainers = Object.values(trainers)
        sess[i].contact = null
        sess[i].contact2 = null
        sess[i].organization = null
        sess[i].to_send = toSend
        sess[i].multi = true

        Sessions.create(sess[i]).then(res => {
          this.saveCallback(res, sess[i].id, toSend)
        })

        break
      }
    },

    saveCallback (res, id, toSend) {
      this.$snotify.success('Session #' + res.data.reference + ' created')
      let index = this.sessions.findIndex(s => s.id = id)
      this.removeSession(index)
      if (this.sessions.length === 0) {
        setTimeout(() => {
          this.$router.push({ name: 'sessions-index' })
        }, 1000)
      } else {
        this.save(toSend)
      }
    },

    newSession (keepOg = false) {
      let saveOg = {}
      if (keepOg) {
        saveOg = JSON.parse(JSON.stringify({
          organizations: this.newItem.organizations,
          contacts: this.newItem.contacts,
          contact_id: this.newItem.contact_id,
          contact2_id: this.newItem.contact2_id,
          organization: this.newItem.organization,
          contact: this.newItem.contact,
          loadingOrg: this.newItem.loadingOrg,
          orga_input: this.newItem.orga_input,
          organization_id: this.newItem.organization_id,
          topic_id: this.newItem.topic_id
        }))
      }

      this.newItem = {
        id: this.uuid(),
        application: Consts.APPLICATION.BEESECURE, //les sessions multiples sont exclusivement pour les school training de bee secure
        session_type: Consts.SCHOOL,
        organizations: [],
        contacts: [],
        organization: null,
        contact: {},
        dates: [
          {
            start: null,
            end: null,
            date_start: null,
            participants_start: null,
            participants_end: null,
            clock_end: null,
            total_time: null,
            clock_participants_start: null,
            clock_participants_start_hour: '',
            clock_participants_start_min: '',
            clock_participants_end_hour: '',
            clock_participants_end_min: ''
          }
        ],
        is_validated: false,
        loadingOrg: false,
        orga_input: "",
        beamer: true,
        screen: true,
        loudspeak: true,
        internet: true,
        socket: true,
        usb: true,
        is_online: false,
        is_inclusive: false,
        status: Consts.DRAFT
      }

      Object.assign(this.newItem, saveOg)
    },

    duplicateSession (i) {
      const s = JSON.parse(JSON.stringify(this.sessions[i]))
      this.newItem = s
    },

    removeSession (i) {
      this.sessions.splice(i, 1)
    },

    validSession () {
      let s = JSON.parse(JSON.stringify(this.newItem))
      if (!this.$refs.form.validate()) {
        this.$snotify.error('Please verify the required fields')
      } else {
        this.sessions.push(s)
        this.resetForm(true)
      }
    },

    saveDateStart (date, i) {
      this.$refs['date_start_' + i][0].save(date)
    },

    saveClockStart (date, i) {
      this.$refs['clock_start_' + i][0].save(date)
    },

    saveClockEnd (date, i) {
      this.$refs['clock_end_' + i][0].save(date)
    },

    parseTime (date) {
      date.clock_start_hour = date.clock_start[0] + date.clock_start[1]
      date.clock_start_min = date.clock_start[3] + date.clock_start[4]
      date.clock_end_hour = date.clock_end[0] + date.clock_end[1]
      date.clock_end_min = date.clock_end[3] + date.clock_end[4]

      date.clock_participants_start_hour = date.clock_participants_start[0] + date.clock_participants_start[1]
      date.clock_participants_start_min = date.clock_participants_start[3] + date.clock_participants_start[4]
      date.clock_participants_end_hour = date.clock_participants_end[0] + date.clock_participants_end[1]
      date.clock_participants_end_min = date.clock_participants_end[3] + date.clock_participants_end[4]

      return date
    },

    fetchParams () {
      this.loading = true
      Params.getList('topics', { is_enabled: 1, application: Consts.APPLICATION.BEESECURE }).then(res => {
        this.topics = res.data
      })
      Params.getList('targets-groups', { is_enabled: 1, session_type: Consts.SCHOOL }).then(res => {
        this.targetGroups = res.data
        this.targetGroups = this.targetGroups.sort((a, b) => (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0))
      })
      Params.getList('groups', { role: Consts.TRAINER }).then(res => {
        this.groups = res.data

        let groups = res.data
        for (let i = 0; i < groups.length; i++) {
          if (groups[i].users && groups[i].users.length > 0) {
            for (let j = 0; j < groups[i].users.length; j++) {
              let is = this.trainers.map(function (e) {
                return e.user_id
              }).indexOf(groups[i].users[j].id)

              if (is == -1 && groups[i].users[j].is_enabled) {
                groups[i].users[j]['fullname'] = groups[i].users[j]['firstname'] + " " + groups[i].users[j]['lastname']
                this.trainers.push(groups[i].users[j])
              }
            }
          }
        }
        this.trainers = this.trainers.sort((a, b) => (a.firstname > b.firstname) ? 1 : ((b.firstname > a.firstname) ? -1 : 0))

        //Supprime les doublons dans plusieurs groupes
        var uniq = new Set(this.trainers.map(e => JSON.stringify(e)))
        var result = Array.from(uniq).map(e => JSON.parse(e))

        this.trainers = result
      })
      IsoLangs.getSpoken().then(res => {
        this.langs = res.data
        this.loading = false
      })
    },

    fetchOrganizationContacts () {
      Organizations.getContactList(this.newItem.organization_id, { is_enabled: 1 }).then(res => {
        this.newItem[['contacts']] = res.data
      })
    },

    onOrgCreated (val) {
      this.newItem.organizations.push(val)
      this.newItem.organization = val
      this.onSelectOrg(val)
    },

    onSelectOrg (val) {
      if (val._isNew) {
        this.organizationDialog = true
        this.newItem.organization_id = null
        return
      }

      if (val && val.id) {
        this.newItem.organization_id = val.id
      }
      this.newItem.contact_id = null
      this.newItem.contact2_id = null
      this.newItem.contact = null
      this.newItem.contact2 = null
      this.fetchOrganizationContacts()
      this.$refs.form.resetValidation()
    },

    resetForm (keepOg = false) {
      this.newSession(keepOg)
      this.$refs.form.resetValidation()
    },

    onOrgaChange () {

      // cancel pending call
      clearTimeout(this._timerId)

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        if (this.source) {
          this.source.cancel()
        }

        let CancelToken = axios.CancelToken
        this.source = CancelToken.source()

        let prev = ""
        if (this.currentInput) {
          for (let i in this.currentInput) {
            prev += this.currentInput[i]
          }
        }

        let selOrga = false
        if (this.newItem.organization && this.newItem.organization.name == this.newItem.orga_input) {
          selOrga = true
        }

        if (this.newItem.orga_input && this.newItem.orga_input.length > 2 && (this.newItem.orga_input != prev) && !selOrga) {
          this.newItem.loadingOrg = true

          let params = {
            keywords: this.newItem.orga_input,
            'is_enabled': 1,
            sortBy: 'name'
          }

          axios.get('/organizations', {
            cancelToken: this.source.token,
            params: params
          }).then(res => {
            this.newItem.organizations = res.data
            this.newItem.organizations.push({ _isNew: true })
            let input = Object.assign({}, this.newItem.orga_input)
            this.currentInput = input
            this.newItem.loadingOrg = false
          })
        }
      }, 350)
    },

    onSelectContact (val, type) {
      switch (type) {
        case 1:
          this.newItem.contact_id = val.id
          break
        case 2:
          this.newItem.contact2_id = val.id
          break
      }
    },

    fullname: item => item.firstname + ' ' + item.lastname,

    newContact (field, obj) {
      this.currentOrg = this.newItem.organization_id
      this.contactDialog = true
      this.newContactField = field
      this.newContactObj = obj
    },

    onContactCreated (val) {
      this.fetchOrganizationContacts()
      this.newItem[this.newContactField] = val.id
      this.newItem[this.newContactObj] = val
    },

    newDate () {
      this.newItem.dates.push({})
    },

    deleteDate (i) {
      this.newItem.dates.splice(i, 1)
    },

    uuid () {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      )
    },
    updateDuration (d) {
      if(d.clock_start_hour !== undefined && d.clock_start_min !== undefined && d.clock_end_hour !== undefined && d.clock_end_min !== undefined) {
        d.total_time = (""+Math.round(this.getDecimalHours(`${d.clock_start_hour}:${d.clock_start_min}`, `${d.clock_end_hour}:${d.clock_end_min}`)*100)/100).replace('.', ',')
      }
    }
  }
}
</script>
<style>
.grid-spacing {
  padding: 0 8px 4px;
}

.tb-unhover > .v-table__overflow > .v-datatable > tbody > tr:hover {
  background: unset;
}

.setup-cb
  > .v-input__control
  > .v-input__slot
  > .v-input--selection-controls__input {
  margin-right: 0px;
}

.multi-table > .v-table__overflow > table.v-table tbody td:first-child,
.multi-table > .v-table__overflow > table.v-table tbody td:not(:first-child),
.multi-table > .v-table__overflow > table.v-table tbody th:first-child,
.multi-table > .v-table__overflow > table.v-table tbody th:not(:first-child),
.multi-table > .v-table__overflow > table.v-table thead td:first-child,
.multi-table > .v-table__overflow > table.v-table thead td:not(:first-child),
.multi-table > .v-table__overflow > table.v-table thead th:first-child,
.multi-table > .v-table__overflow > table.v-table thead th:not(:first-child) {
  padding: 0 6px;
}
.ams-form .v-text-field {
  margin-top: 0;
  padding-top: 0;
}
</style>
