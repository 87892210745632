import axios from 'axios'
import './common'

export default {
    getList: (type, params) => axios.get(`/params/${type}`, { params: params }),
    get: (type, id) => axios.get(`/params/${type}/${id}`),
    create: (type, data, formData = false) => {
        if(!formData) {
            return axios.post(`/params/${type}`, data)
        }
        else {
            return axios.post(`/params/${type}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        }
    },
    update: (type, id, data, formData = false) => {
        if(!formData) {
            return axios.put(`/params/${type}/${id}`, data)
        }
        else {
            data.append("_METHOD", "PUT")
            return axios.post(`/params/${type}/${id}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        }
    },
    delete: (type, id) => axios.delete(`/params/${type}/${id}`),
    enable: (type, id) => axios.patch(`/params/${type}/${id}/enable`),
    disable: (type, id) => axios.patch(`/params/${type}/${id}/disable`),
    multi(type, data) {
        data.append("_METHOD", "PUT");
        return axios.post(`/params/${type}/multi`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },
}
