<template>
  <div class="ma-3">
    <div class="title mb-3">
      Settings <v-icon>mdi-chevron-right</v-icon> Global values
    </div>

    <div
      layout="column"
      layout-align="center center"
    >
      <div
        layout="column"
        class="field-width"
      >
        <div class="title font-weight-light mb-3">
          Limits
        </div>

        <v-text-field
          v-model="params.max_sessions_per_hour"
          outline
          label="Maximum sessions per hour"
          type="number"
        />
        <v-text-field
          v-model="params.max_lessons"
          outline
          label="Maximum number of lessons"
          type="number"
        />
        <v-text-field
          v-model="params.contracts_next_year"
          outline
          label="Opening date of contracts (DD.MM)"
          type="text"
        />
        <v-text-field
          v-model="params.nb_days_training_close"
          outline
          label="Number of days before registration for Freelances training closes"
          type="number"
        />
        <v-textarea
          v-model="params.training_close_message"
          outline
          label="Message when registration for Freelances training closes"
        />
        <v-text-field
          v-model="params.nb_hours_per_year"
          outline
          label="Number max of hours per year for a trainer/AS (Teachers are not concerned)"
          type="number"
        />
        <div class="title font-weight-light mb-3">
          Information
        </div>

        <v-text-field
          v-model="params.team_phone"
          outline
          label="Group phone number"
        />

        <div class="title font-weight-light mb-3">
          Logistic
        </div>

        <div
          layout="row"
          layout-align="start center"
        >
          <v-text-field
            v-model="logisticName"
            label="Logistic print template"
            prepend-icon="mdi-attachment"
            @click="pickFileLogistic()"
          />
          <v-btn
            :disabled="!params.logistic_doc_id"
            flat
            outline
            color="primary"
            title="Download"
            @click="download(params.logistic_doc_id)"
          >
            <v-icon>mdi-download</v-icon>
            Download
          </v-btn>
        </div>

        <input
          :ref="`fileLogistic`"
          type="file"
          style="display: none"
          accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          @change="onFileLogisticPicked($event)"
        >

        <div
          layout="row"
          layout-align="center center"
        >
          <v-btn
            depressed
            color="primary"
            @click="save"
          >
            Save
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import Params from '@/api/params'
    import axios from 'axios'
    import FormDataMixin from '@/mixins/formdata'

    const KEYS = ['max_sessions_per_hour', 'max_lessons', 'team_phone', 'logistic_doc_id', 'contracts_next_year', 'nb_days_training_close', 'training_close_message', 'nb_hours_per_year'];

    export default {
        mixins: [FormDataMixin],
        data () {
            return {
                params: {},
                groups: [],
                logisticData: null,
                logisticName: "",
            }
        },
        mounted () {
            this.fetch();
        },
        methods: {
            fetch () {
                Params.getList('groups').then(res => {
                    this.groups = res.data;
                });

                Params.getList('params').then(res => {
                    this.params = {};

                    for (let i = 0; i < res.data.length; ++i) {
                        const p = res.data[i];

                        if (p.name === 'contracts_next_year') {
                            const dd = p.value.split('-')
                            this.params[p.name] = dd[1] + '.' + dd[0]
                        } else {
                            this.params[p.name] = p.value
                        }
                    }

                    for (let i = 0; i < KEYS.length; ++i) {
                        if (!this.params.hasOwnProperty(KEYS[i])) {
                            this.params[KEYS[i]] = null;
                        }
                    }
                })
            },

            save () {
                // Convert the data to FormData for proper file upload
                let formData = new FormData();
                let params = Object.assign({}, this.params)

                if (this.logisticData && this.logisticName) {
                    formData.append("logistic_doc_id", this.logisticData, this.logisticName);
                    params['logistic_doc_id'] = 1;
                }

                if (params['contracts_next_year'] !== '') {
                    let dd = params['contracts_next_year'].split('.')
                    if (dd.length !== 2) {
                        dd = params['contracts_next_year'].split('/')
                    }
                    if (dd.length === 2) {
                        params['contracts_next_year'] = dd[1] + '-' + dd[0]
                    } else {
                      this.$snotify.error('Opening date of contracts format is not valid, it should be DD.MM');
                      return
                    }
                }

                this._convertObjectToFormData(formData, {params: params});

                Params.multi('params', formData).then(() => {
                    this.$snotify.success('The parameters have been successfully saved');
                })
            },
            pickFileLogistic() {
                this.$refs['fileLogistic'].click();
            },
            onFileLogisticPicked(e) {
                const files = e.target.files;
                if (files[0] !== undefined) {
                    this.logisticData = files[0];
                    this.logisticName = files[0].name;
                }
            },
             download (id) {
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";

                axios.get('/assets/logistic/' + id, {responseType: 'blob',}).then(res => {
                    let blob = new Blob([res.data], {type: "octet/stream"});

                    if (window.navigator && window.navigator.msSaveOrOpenBlob){
                        window.navigator.msSaveBlob(blob, "logistic_template_" + id + ".docx");
                    }
                    else
                    {
                        let url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.download = "logistic_template_" + id + ".docx";
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                });
            }
        }
    }
</script>
