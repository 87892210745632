<template>
  <div class="ma-3">
    <div class="title mb-3">
      Settings <v-icon>mdi-chevron-right</v-icon> Users
      <v-icon>mdi-chevron-right</v-icon>
      {{ isNew ? "New" : user.firstname + " " + user.lastname }}
    </div>

    <v-form
      ref="form"
      v-model="formValid"
      layout="column"
      layout-align="center center"
      autocomplete="off"
      @submit.prevent="save"
    >
      <div>
        <div class="title font-weight-light mb-3">
          User information
        </div>
        <v-text-field
          v-model="user.firstname"
          outline
          label="First name"
          class="field-width"
          :rules="[v => !!v || 'This field is required']"
        />
        <v-text-field
          v-model="user.lastname"
          outline
          label="Last name"
          class="field-width"
          :rules="[v => !!v || 'This field is required']"
        />
        <v-text-field
          v-model="user.email"
          outline
          label="E-mail"
          class="field-width"
          type="email"
        />
        <v-text-field
          v-model="user.phone"
          outline
          label="Phone number"
          class="field-width"
        />
        <v-text-field
          v-model="user.comment"
          outline
          label="Comment"
          class="field-width"
        />

        <div class="title font-weight-light mb-3">
          Login information
        </div>
        <v-text-field
          v-model="user.login"
          outline
          label="Login"
          class="field-width"
          :rules="[v => !!v || 'This field is required']"
        />
        <v-text-field
          v-model="user.password"
          outline
          label="Password"
          class="field-width"
          type="password"
          autocomplete="new-password"
        />

        <div class="title font-weight-light mb-3">
          Group information
        </div>
        <v-select
          v-model="user.groups"
          v-focus
          multiple
          outline
          label="Groups"
          :items="groups"
          item-value="id"
          item-text="name"
        />

        <v-checkbox
          v-if="isTra"
          v-model="user.is_candidate"
          label="This is a candidate (non official trainer)"
          :false-value="0"
          :true-value="1"
        />

        <template v-if="isCT">
          <v-select
            v-model="user.application"
            v-focus
            outline
            label="Application"
            :items="applications"
            :rules="[v => !!v || v === 0 || 'This field is required']"
          />
          <v-checkbox
            v-model="user.is_admin"
            label="This is an administrator (can access the administration)"
            :false-value="0"
            :true-value="1"
          />
        </template>
      </div>

      <v-btn
        depressed
        color="primary"
        @click="save"
      >
        Save
      </v-btn>
      <router-link
        :to="{ name: 'admin-settings-users-index' }"
        class="grey--text"
      >
        Cancel
      </router-link>
    </v-form>
  </div>
</template>

<script>
import Params from "@/api/params";
import Consts from "@/consts";
import GlobalMixin from "@/mixins/global";

export default {
  mixins: [GlobalMixin],
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.name;
    });
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      formValid: false,
      groups: [],
      prevRoute: null
    };
  },
  computed: {
    user: {
      get() {
        return this.value ? this.value : {};
      },
      set(x) {
        this.$emit("input", x);
      }
    },
    isNew() {
      return this.$route.params.id === "new";
    },
    isTra() {
      if (this.user.groups && this.user.groups.length) {
        for (let i = 0; i < this.user.groups.length; i++) {
          let is = this.groups
            .map(function(t) {
              return t.id;
            })
            .indexOf(this.user.groups[i]);
          let group = this.groups[is];
          if (group && group.role == Consts.TRAINER) {
            return true;
          }
        }
      }
      return false;
    },
    isCT() {
      if (this.user.groups && this.user.groups.length) {
        for (let i = 0; i < this.user.groups.length; i++) {
          let is = this.groups
            .map(function(t) {
              return t.id;
            })
            .indexOf(this.user.groups[i]);
          let group = this.groups[is];
          if (group && group.role == Consts.COORDINATION) {
            return true;
          }
        }
      }
      return false;
    }
  },
  mounted() {
    Params.getList("groups", { is_enabled: 1 }).then(res => {
      this.groups = res.data;
    });
  },
  methods: {
    fetch() {
      Params.get("users", this.$route.params.id).then(res => {
        this.user = res.data;
        if (this.user && this.user.groups && this.user.groups.length > 0) {
          let arr = [];
          for (let i = 0; i < this.user.groups.length; i++) {
            arr.push(this.user.groups[i].id);
          }
          this.user.groups = arr;
        }
      });
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.isNew) {
        Params.create("users", this.user).then(() => {
          this.$router.push({
            name:
              this.prevRoute !== null
                ? this.prevRoute
                : "admin-settings-users-index"
          });
          this.$snotify.success(
            `The user "${this.user.firstname} ${this.user.lastname}" has been created`
          );
        });
      } else {
        Params.update("users", this.user.id, this.user).then(() => {
          this.$router.push({
            name:
              this.prevRoute !== null
                ? this.prevRoute
                : "admin-settings-users-index"
          });
          this.$snotify.success(
            `The user "${this.user.firstname} ${this.user.lastname}" has been updated`
          );
        });
      }
    }
  }
};
</script>
