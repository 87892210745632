<template>
  <div
    layout="row"
    layout-align="start start"
    class="mt-2"
  >
    <div
      layout="column"
      class="field-width"
    >
      <v-timeline align-top>
        <template v-for="(h,i) in historic">
          <v-timeline-item
            v-if="getEvent(h)"
            :key="i"
            color="primary"
            small
            fill-dot
            right
          >
            <div
              slot="opposite"
              class="mt-3 caption"
            >
              {{ formatDateHuman(h.created_at) }}
            </div>
            <template slot="icon">
              <v-avatar
                :title="h.created_by ? h.created_by : 'System'"
                color="primary"
                size="32"
                style="min-width: 32px"
              >
                <span class="white--text body-2">
                  <template v-if="h.created_by">
                    {{ h.created_by[0] }}{{ h.created_by.split(' ')[1][0] }}
                  </template>
                  <template v-else>S</template>
                </span>
              </v-avatar>
            </template>
            <v-card class="mt-1">
              <v-card-text class="pa-2">
                {{ getEvent(h) }}
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </template>
      </v-timeline>
    </div>
  </div>
</template>

<script>
import DateMixin from '@/mixins/date'
import GroupsMixin from '@/mixins/groups'
import Params from '@/api/params'

export default {
  mixins: [DateMixin, GroupsMixin],
	props: {
			user: {
				type: Object,
				required: true,
				default: () => {}
			}
	},
  data () {
    return {
			historic: [],
			references: {}
		}
  },
  mounted () {
    this.fetch()
  },
  methods: {
		fetch () {
				Params.getList(`users/${this.user.id}/historic`).then(res => {
						this.historic = res.data.lines
						this.references = res.data.references
				})
		},
		getReference(c, refs = []){
			if(!this.references[c]){
				return
			}

			let ref = null
			refs.forEach(r => {
				if(r.ref_type === c){
					ref = r
				}
			})

			if(!ref){
				return
			}

			return this.references[c][ref.ref_id]
		},
    getEvent (historic) {
			switch (historic.historisable_type) {
				case 'App\\Models\\SessionTrainer':
					var sess = this.getReference('App\\Models\\Session', historic.references)
					var st = this.getReference('App\\Models\\SessionTrainer', historic.references)

					if(!sess || !st){
						return
					}

					if (historic.type == 'C') {
						if (st.is_request || (this.user.id == historic.creator_id)) {
							return this.user.firstname + ' ' + this.user.lastname + ' asked to be assigned to the session #' + sess.reference + '.'
						} else {
							return 'User has been assigned to the session #' + sess.reference + '.'
						}
					} else if (historic.type == 'D') {
						if (this.user.id == historic.creator_id) {
							return 'User has left the session #' + sess.reference + '.'
						} else {
							return 'User has been removed from the #' + sess.reference + '.'
						}
					}
				break
				case 'App\\Models\\Skill':
					var t = this.getReference('App\\Models\\Topic', historic.references)

					if(!t){
						return
					}

					if (historic.type == 'C') {
						return 'Skill "' + t.name + '" has been added.'
					}
					else if (historic.type == 'U') {
						switch(historic.change.field){
							case 'role':
								return 'Role on skill "' + t.name + '" has been updated from "' + this.getSkillRoleLabel(historic.change.before) + '" to "' + this.getSkillRoleLabel(historic.change.after) + '".'
							case 'is_former':
								if(historic.change.after == 1){
									return 'User is now former for skill "' + t.name + '".'
								}else{
									return 'User is not former anymore for skill "' + t.name + '".'
								}
							case 'is_enabled':
								if(historic.change.after == 1){
									return 'Skill "' + t.name + '" has been enabled.'
								}else{
									return 'Skill "' + t.name + '" has been disabled.'
								}
						}
					}
				break
				case 'App\\Models\\UserSeason':
					var s = this.getReference('App\\Models\\Season', historic.references)

					if(!s){
						return
					}

					if (historic.type == 'C') {
						return 'Season on team "' + s.team.name + '" ' + this.formatDate(s.start) + ' - ' + this.formatDate(s.end) + ' has been added on skill "' + s.topic.name + '".'
					}
					else if (historic.type == 'U') {
						switch(historic.change.field){
							case 'is_enabled':
								if(historic.change.after == 1){
									return 'Season on team "' + s.team.name + '" ' + this.formatDate(s.start) + ' - ' + this.formatDate(s.end) + ' has been enabled on skill "' + s.topic.name + '".'
								}else{
									return 'Season on team "' + s.team.name + '" ' + this.formatDate(s.start) + ' - ' + this.formatDate(s.end) + ' has been disabled on skill "' + s.topic.name + '".'
								}
						}
					}
				break
			}

			return
    }
  }
}
</script>

<style>
.v-timeline-item__opposite {
  align-self: start;
}
</style>
