<template>
  <div>
    <div>
      <v-subheader>
        Dates
      </v-subheader>
      <div
        v-if="session.dates && session.dates.length == 1"
        layout="row"
        layout-align="center center"
      >
        <div
          layout="column"
          layout-align="center center"
        >
          <div
            style="color:#f4b944"
            class="display-4 font-weight-bold"
          >
            {{ formatDay(session.dates[0].start) }}
          </div>
          <div class="display-1 font-weight-bold">
            {{ formatMonthYear(session.dates[0].start) }}
          </div>
          <div class="font-weight-light title mt-2">
            at {{ formatTime(session.dates[0].start) }}<span v-if="session.dates.length > 1"> until {{ formatDateHuman(session.dates[session.dates.length - 1].end) }}</span><span v-else>-{{ formatTime(session.dates[0].end) }}</span>
          </div>
          <em>
            Participants will be present from {{ formatTime(session.dates[0].participants_start) }} to {{ formatTime(session.dates[session.dates.length - 1].participants_end) }}
          </em>
        </div>
      </div>
      <div
        v-else-if="session.dates && session.dates.length > 1"
        layout="row"
        layout-align="start start"
      >
        <div
          layout="column"
          layout-align="start start"
        >
          <div
            v-for="d in session.dates"
            :key="d.id"
            class="mb-2"
          >
            <div layout="row">
              <div class="headline font-weight-bold ml-1">
                {{ formatDate(d.start) }}
              </div>
            </div>
            <div
              layout="row"
              class="font-weight-light title mt-2"
            >
              {{ formatTime(d.start) }}-{{ formatTime(d.end) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dateMixin from '@/mixins/date'
export default {
	mixins: [dateMixin],
	props: {
		session: {
			type: Object,
			required: true,
			default: () => {}
		}
	}
}
</script>
<style scoped>
	.v-subheader {
		padding: 0 10px;
	}
</style>
