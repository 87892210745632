<template>
  <div class="my-3">
    <div
      layout="row"
      layout-align="start start"
      class="mt-2"
    >
      <div class="title px-3">
        Logistics
      </div>
      <span flex />
      <v-btn
        icon
        :loading="loadingPrint"
        :disabled="loadingPrint"
        @click="print"
      >
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <div
        layout="column"
        layout-align="center end"
        class="pr-3 pb-1"
      >
        <v-text-field
          v-model="keywords"
          placeholder="Quick search..."
          outline
          single-line
          hide-details
          class="small-text-field"
        />
        <a
          href="#"
          class="caption grey--text"
          @click.prevent="toggleAdvancedSearch()"
        >Advanced search</a>
      </div>
    </div>

    <div
      v-show="advancedSearch"
      class="index-header pb-1"
    >
      <v-layout
        row
        wrap
        class="px-2"
      >
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-text-field
            v-model="params.start_text"
            label="Start date"
            outline
            hide-details
            @blur="params.start = parseDate(params.start_text)"
            @change="fetch"
          >
            <div slot="prepend-inner">
              <v-menu
                ref="searchStart"
                :close-on-content-click="false"
                :return-value.sync="params.start"
                transition="scale-transition"
                offset-y
                full-width
                lazy
              >
                <v-icon slot="activator">
                  event
                </v-icon>
                <v-date-picker
                  v-model="params.start"
                  first-day-of-week="1"
                  @change="fetch"
                  @input="$refs.searchStart.save(params.start); params.start_text = americanizeDate(params.start)"
                />
              </v-menu>
            </div>
          </v-text-field>
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-text-field
            v-model="params.end_text"
            label="End date"
            outline
            hide-details
            @blur="params.end = parseDate(params.end_text)"
            @change="fetch"
          >
            <div slot="prepend-inner">
              <v-menu
                ref="searchEnd"
                :close-on-content-click="false"
                :return-value.sync="params.end"
                transition="scale-transition"
                offset-y
                full-width
                lazy
              >
                <v-icon slot="activator">
                  event
                </v-icon>
                <v-date-picker
                  v-model="params.end"
                  first-day-of-week="1"
                  @change="fetch"
                  @input="$refs.searchEnd.save(params.end); params.end_text = americanizeDate(params.end)"
                />
              </v-menu>
            </div>
          </v-text-field>
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-text-field
            v-model="params.school"
            label="School / Class / Extra"
            outline
            hide-details
            @input="fetch"
          />
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-text-field
            v-model="params.contact"
            label="Contacts"
            outline
            hide-details
            @input="fetch"
          />
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-select
            v-model="params.status"
            v-focus
            label="Status"
            hide-details
            multiple
            outline
            :items="status"
            dense
            @change="fetch"
          >
            <template
              slot="selection"
              slot-scope="{ index }"
            >
              <span
                v-if="index === 0"
                class="grey--text caption"
              >{{ params.status.length }} selected</span>
            </template>
          </v-select>
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-select
            v-model="params.trainers"
            v-focus
            label="Trainers"
            hide-details
            multiple
            outline
            :items="trainers"
            item-value="id"
            item-text="fullname"
            dense
            @change="fetch"
          >
            <template
              slot="selection"
              slot-scope="{ index }"
            >
              <span
                v-if="index === 0"
                class="grey--text caption"
              >{{ params.trainers.length }} selected</span>
            </template>
          </v-select>
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-select
            v-model="params.session_type"
            v-focus
            label="Session type"
            hide-details
            multiple
            outline
            small-chips
            deletable-chips
            :items="sessionTypes"
            dense
            @change="fetch"
          >
            <template
              slot="selection"
              slot-scope="{ index }"
            >
              <span
                v-if="index === 0"
                class="grey--text caption"
              >{{ params.session_type.length }} selected</span>
            </template>
          </v-select>
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-select
            v-model="params.school_type_id"
            v-focus
            label="School type"
            hide-details
            multiple
            outline
            small-chips
            deletable-chips
            :items="schoolTypes"
            item-value="id"
            item-text="name"
            dense
            @change="fetch"
          >
            <template
              slot="selection"
              slot-scope="{ index }"
            >
              <span
                v-if="index === 0"
                class="grey--text caption"
              >{{ params.school_type_id.length }} selected</span>
            </template>
          </v-select>
        </v-flex>
      </v-layout>
      <v-layout
        row
        justify-end
        class="px-2"
      >
        <v-btn
          color="error"
          class="mx-2"
          @click="onSelectMode(true)"
        >
          <v-icon left>
            mdi-eraser-variant
          </v-icon> Reset
        </v-btn>
      </v-layout>
    </div>

    <div
      v-if="advancedSearch"
      class="pb-4"
    >
      <v-divider
        v-show="advancedSearch"
        class="mx-3"
      />
      <div
        v-if="advancedSearch"
        class="toolbar-toggle mb-4"
        @click="toggleAdvancedSearch()"
      >
        <v-icon size="18px">
          mdi-chevron-up
        </v-icon>
      </div>
    </div>

    <v-data-table
      v-model="selected"
      class="px-3"
      :headers="headers"
      :items="sessions"
      :pagination.sync="pagination"
      select-all
      :total-items="totalSessions"
      :loading="loading"
      hide-actions
    >
      <template
        slot="items"
        slot-scope="props"
      >
        <td>
          <v-checkbox
            v-model="props.selected"
            hide-details
          />
        </td>
        <td>
          <router-link
            class="black--text mt-2"
            :to="{name: 'sessions-show-info', params: {id: props.item.id}, query:{from: 'logistics-index'}}"
          >
            {{ props.item.reference }}
          </router-link>
        </td>
        <td>
          <v-chip
            :color="sessionTypeColor(props.item.session_type)"
            class="lighten-3"
            small
          >
            {{ sessionTypeStr(props.item.session_type) }}
          </v-chip>
        </td>
        <td>
          <v-menu
            v-model="props.item.date_popover_open"
            :close-on-content-click="false"
            offset-y
            offset-x
          >
            <div
              slot="activator"
              layout="column"
              @click="openDates(props.item)"
            >
              <div
                v-for="d in props.item.dates"
                :key="'date_' + props.item.id + d.id"
                layout="row"
              >
                {{ formatDayHuman(d.start) }}
                {{ formatTime(d.start) }}-{{ formatTime(d.end) }}
              </div>
            </div>

            <v-card style="width: 600px">
              <v-card-title
                layout="column"
                layout-align="start start"
                class="pb-0"
              >
                <div
                  layout="row"
                  layout-align="start center"
                  class="ml-1 mr-1"
                  style="width: 100%"
                >
                  <v-avatar
                    :title="sessionStatusStrLong(props.item.status)"
                    :color="sessionStatusColor(props.item.status)"
                    :size="32"
                    class="black--text font-weight-medium"
                  >
                    {{ sessionStatusStr(props.item.status) }}
                  </v-avatar>

                  <div class="title ml-2">
                    <b>#{{ props.item.reference }} /</b>
                    <span
                      style="font-weight: 400 !important;"
                      class="ml-2"
                    >{{ props.item.organization ? props.item.organization.name : '' }}</span>
                  </div>
                  <div
                    layout="column"
                    flex
                    class="full-width"
                  >
                    <div
                      layout="row"
                      layout-align="center end"
                    >
                      <span flex />
                      <v-btn
                        icon
                        class="mr-1"
                        @click="closeDates(props.item)"
                      >
                        <v-icon color="red">
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>

                <div
                  layout="row"
                  layout-align="start center"
                >
                  <v-chip
                    :color="sessionTypeColor(props.item.session_type)"
                    class="lighten-3"
                    small
                  >
                    {{ sessionTypeStr(props.item.session_type) }}
                  </v-chip>

                  <v-rating
                    v-if="props.item.organization"
                    background-color="#AAAAAA"
                    dense
                    small
                    class="mr-1"
                    readonly
                    :value="parseInt(props.item.organization.mark)"
                  />
                  <v-rating
                    v-else
                    background-color="#AAAAAA"
                    dense
                    small
                    class="mr-1"
                    readonly
                    :value="0"
                  />
                </div>
              </v-card-title>
              <v-card-text>
                <v-divider />
                <div class="subheader font-weight-regular mt-3">
                  Session's dates:
                </div>

                <v-list>
                  <v-list-tile
                    v-for="(d, i) in props.item.dates"
                    :key="props.item.id + '_date' + i"
                  >
                    <v-list-tile-avatar>
                      <v-icon>mdi-calendar</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-title>
                      {{ formatDateTime(d.start) }} <v-icon :size="18">
                        mdi-arrow-right
                      </v-icon> {{ formatDateTime(d.end) }}
                    </v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-card-text>
            </v-card>
          </v-menu>
        </td>
        <td>
          <v-menu
            v-model="props.item.orga_popover_open"
            :close-on-content-click="false"
            offset-y
            offset-x
          >
            <div
              slot="activator"
              layout="column"
            >
              {{ props.item.organization ? props.item.organization.name : '' }}
            </div>
            <v-card>
              <v-card-title
                layout="column"
                layout-align="start start"
                class="pb-0"
              >
                <div
                  layout="row"
                  layout-align="start center"
                  class="ml-1 mr-1"
                  style="width: 100%"
                >
                  <v-rating
                    v-if="props.item.organization"
                    background-color="#AAAAAA"
                    dense
                    small
                    class="mr-1"
                    readonly
                    :value="parseInt(props.item.organization.mark)"
                  />
                  <v-rating
                    v-else
                    background-color="#AAAAAA"
                    dense
                    small
                    class="mr-1"
                    readonly
                    :value="0"
                  />
                  {{ props.item.organization ? props.item.organization.name : '' }}
                  <div
                    layout="column"
                    flex
                    class="full-width"
                  >
                    <div
                      layout="row"
                      layout-align="center end"
                    >
                      <span flex />
                      <v-btn
                        icon
                        class="mr-1"
                        @click="props.item.orga_popover_open= false"
                      >
                        <v-icon color="red">
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-card-title>

              <v-card-text v-if="props.item.organization">
                {{ props.item.organization.number }} {{ props.item.organization.street }}<br>
                {{ props.item.organization.zip_code }} {{ props.item.organization.city }}
              </v-card-text>
            </v-card>
          </v-menu>
        </td>
        <td>
          <v-menu
            v-model="props.item.teacher_popover_open"
            :close-on-content-click="false"
            offset-y
            offset-x
          >
            <div
              slot="activator"
              layout="column"
              flex
            >
              <div layout="row">
                <span v-if="props.item.class_name && props.item.room">
                  {{ props.item.class_name }} / {{ props.item.room }}
                </span>
                <span v-else-if="props.item.class_name || props.item.room">
                  {{ props.item.class_name }} {{ props.item.room }}
                </span>
                <span v-else>
                  -&nbsp;
                </span>

                <span
                  v-if="props.item.lang_id"
                  class="font-weight-bold text-uppercase"
                >[{{ props.item.lang_id }}]</span>
              </div>
              <div
                layout="row"
                class="printable"
              >
                {{ props.item.referent_fullname }} {{ props.item.referent_phone }}
              </div>
            </div>
            <v-card style="width: 600px">
              <v-card-title
                layout="column"
                layout-align="start start"
                class="pb-0"
              >
                <div
                  layout="row"
                  layout-align="start center"
                  class="ml-1 mr-1"
                  style="width: 100%"
                >
                  <v-avatar
                    :title="sessionStatusStrLong(props.item.status)"
                    :color="sessionStatusColor(props.item.status)"
                    :size="32"
                    class="black--text font-weight-medium"
                  >
                    {{ sessionStatusStr(props.item.status) }}
                  </v-avatar>
                  <div class="title ml-2">
                    <b>#{{ props.item.reference }} /</b>
                    <span
                      style="font-weight: 400 !important;"
                      class="ml-2"
                    >{{ props.item.organization ? props.item.organization.name : '' }}</span>
                  </div>
                  <div
                    layout="column"
                    flex
                    class="full-width"
                  >
                    <div
                      layout="row"
                      layout-align="center end"
                    >
                      <span flex />
                      <v-btn
                        icon
                        class="mr-1"
                        @click="props.item.teacher_popover_open = false"
                      >
                        <v-icon
                          left
                          color="red"
                          right
                        >
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div
                  layout="row"
                  layout-align="start center"
                >
                  <v-chip
                    :color="sessionTypeColor(props.item.session_type)"
                    class="lighten-3"
                    small
                  >
                    {{ sessionTypeStr(props.item.session_type) }}
                  </v-chip>
                  <v-rating
                    v-if="props.item.organization"
                    background-color="#AAAAAA"
                    dense
                    small
                    class="mr-1"
                    readonly
                    :value="parseInt(props.item.organization.mark)"
                  />
                  <v-rating
                    v-else
                    background-color="#AAAAAA"
                    dense
                    small
                    class="mr-1"
                    readonly
                    :value="0"
                  />
                </div>
              </v-card-title>
              <v-card-text>
                <v-divider />
                <div class="mt-3 mb-2">
                  <span class=" font-weight-medium">Session's teacher:</span>
                  {{ props.item.referent_fullname }} {{ props.item.referent_phone }}
                </div>
                <div
                  v-if="props.item.session_type == Consts.SCHOOL"
                  class="mb-2"
                >
                  <span class=" font-weight-medium mt-3 mb-2">Session's class / room:</span>
                  {{ props.item.class_name ? props.item.class_name : "-" }} / {{ props.item.room ? props.item.room : "-" }}
                </div>
                <div
                  v-else
                  class="mb-2"
                >
                  <span class=" font-weight-medium mt-3 mb-2">Extra:</span>
                  {{ props.item.class_name ? props.item.class_name : "-" }}
                </div>
                <div>
                  <span class=" font-weight-medium mt-3 mb-2">Session's concept:</span>
                  {{ props.item.topic ? props.item.topic.name : "/" }}
                </div>
                <div>
                  <span class=" font-weight-medium mt-3 mb-2">Nb of pupils:</span>
                  {{ props.item.nb_participants }}
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
        </td>
        <td>
          <v-icon
            v-if="!isEvent(props.item) && !props.item.beamer"
            title="Video-projector"
          >
            mdi-projector
          </v-icon>
          <v-icon
            v-if="!isEvent(props.item) && !props.item.screen"
            title="Projection screen"
          >
            mdi-projector-screen
          </v-icon>
          <v-icon
            v-if="!isEvent(props.item) && !props.item.loudspeak"
            title="Speakers"
          >
            mdi-speaker-wireless
          </v-icon>
          <v-icon
            v-if="!isEvent(props.item) && !props.item.internet"
            title="Internet connection"
          >
            mdi-web
          </v-icon>
          <v-icon
            v-if="!isEvent(props.item) && !props.item.socket"
            title="Power plug"
          >
            mdi-power-plug
          </v-icon>
          <v-icon
            v-if="!isEvent(props.item) && !props.item.usb"
            title="USB stick"
          >
            mdi-usb
          </v-icon>
        </td>
        <td>
          <v-menu
            v-model="props.item.contact_popover_open"
            :close-on-content-click="false"
            offset-y
            offset-x
          >
            <div
              slot="activator"
              layout="column"
            >
              <div v-if="props.item.contact">
                {{ props.item.contact.firstname }} {{ props.item.contact.lastname }}
              </div>
              <div
                v-if="props.item.contact2"
                class="printable"
              >
                {{ props.item.contact2.firstname }} {{ props.item.contact2.lastname }}
              </div>
            </div>

            <v-card style="width: 600px">
              <v-card-title
                layout="column"
                layout-align="start start"
                class="pb-0"
              >
                <div
                  layout="row"
                  layout-align="start center"
                  class="ml-1 mr-1"
                  style="width: 100%"
                >
                  <v-avatar
                    :title="sessionStatusStrLong(props.item.status)"
                    :color="sessionStatusColor(props.item.status)"
                    :size="32"
                    class="black--text font-weight-medium"
                  >
                    {{ sessionStatusStr(props.item.status) }}
                  </v-avatar>

                  <div class="title ml-2">
                    <b>#{{ props.item.reference }} /</b>
                    <span
                      style="font-weight: 400 !important;"
                      class="ml-2"
                    >{{ props.item.organization ? props.item.organization.name : '' }}</span>
                  </div>
                  <div
                    layout="column"
                    flex
                    class="full-width"
                  >
                    <div
                      layout="row"
                      layout-align="center end"
                    >
                      <span flex />
                      <v-btn
                        icon
                        class="mr-1"
                        @click="props.item.contact_popover_open = false"
                      >
                        <v-icon color="red">
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>

                <div
                  layout="row"
                  layout-align="start center"
                >
                  <v-chip
                    :color="sessionTypeColor(props.item.session_type)"
                    class="lighten-3"
                    small
                  >
                    {{ sessionTypeStr(props.item.session_type) }}
                  </v-chip>

                  <v-rating
                    v-if="props.item.organization"
                    background-color="#AAAAAA"
                    dense
                    small
                    class="mr-1"
                    readonly
                    :value="parseInt(props.item.organization.mark)"
                  />
                  <v-rating
                    v-else
                    background-color="#AAAAAA"
                    dense
                    small
                    class="mr-1"
                    readonly
                    :value="0"
                  />
                </div>
              </v-card-title>
              <v-card-text>
                <v-divider />
                <div class="subheading font-weight-medium mt-3 mb-2">
                  Session's contacts:
                </div>

                <div
                  v-if="props.item.contact"
                  layout="row"
                  class="mr-3"
                >
                  <div
                    layout="column"
                    flex="30"
                  >
                    Main contact:
                  </div>
                  <div
                    layout="column"
                    flex="30"
                  >
                    {{ props.item.contact.firstname }} {{ props.item.contact.lastname }}
                  </div>
                  <div
                    layout="column"
                    flex="20"
                  >
                    <div layout="row">
                      <v-icon
                        small
                        class="mr-1"
                      >
                        mdi-phone
                      </v-icon> {{ props.item.contact.phone ? props.item.contact.phone : "/" }}
                    </div>
                  </div>
                  <div
                    layout="column"
                    flex="20"
                  >
                    <div layout="row">
                      <v-icon
                        small
                        class="mr-1"
                      >
                        mdi-email
                      </v-icon> {{ props.item.contact.email ? props.item.contact.email : "/" }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="props.item.contact2"
                  layout="row"
                  class="mr-3"
                >
                  <div
                    layout="column"
                    flex="30"
                  >
                    Secondary contact:
                  </div>
                  <div
                    layout="column"
                    flex="30"
                  >
                    {{ props.item.contact2.firstname }} {{ props.item.contact2.lastname }}
                  </div>
                  <div
                    layout="column"
                    flex="20"
                  >
                    <div layout="row">
                      <v-icon
                        small
                        class="mr-1"
                      >
                        mdi-phone
                      </v-icon> {{ props.item.contact2.phone ? props.item.contact2.phone : "/" }}
                    </div>
                  </div>
                  <div
                    layout="column"
                    flex="20"
                  >
                    <div layout="row">
                      <v-icon
                        small
                        class="mr-1"
                      >
                        mdi-email
                      </v-icon> {{ props.item.contact2.email ? props.item.contact2.email : "/" }}
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
        </td>
        <td>
          <div v-if="props.item.trainers && props.item.trainers.length">
            <div
              v-for="t in props.item.trainers"
              :key="'trainer_' + props.item.id + t.id"
            >
              {{ t.firstname[0] }}. {{ t.lastname }}
            </div>
          </div>
          <div v-else>
            -
          </div>
        </td>
        <td>
          <v-avatar
            :title="sessionStatusStrLong(props.item.status)"
            :color="sessionStatusColor(props.item.status)"
            :size="32"
            class="black--text font-weight-medium"
          >
            {{ sessionStatusStr(props.item.status) }}
          </v-avatar>
        </td>
        <td>
          <v-btn
            class="mx-0"
            icon
            :loading="loadingPrint && (props.item.id == loadingSess)"
            :disabled="loadingPrint"
            @click="printSession(props.item)"
          >
            <v-icon color="primary">
              mdi-printer
            </v-icon>
          </v-btn>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment'
import Sessions from '@/api/sessions'
import Params from '@/api/params'
import DateMixin from '@/mixins/date'
import SessionsMixin from '@/mixins/sessions'
import Consts from '@/consts'
import Auth from '@/plugins/auth'
import axios from 'axios'

export default {
  mixins: [DateMixin, SessionsMixin],
  data () {
    return {
      sessionTypes: [
        { value: Consts.EVENTS, text: this.sessionTypeStr(Consts.EVENTS) },
        { value: Consts.ADULTS, text: this.sessionTypeStr(Consts.ADULTS) },
        { value: Consts.SCHOOL, text: this.sessionTypeStr(Consts.SCHOOL) },
        { value: Consts.TEACHERS, text: this.sessionTypeStr(Consts.TEACHERS) },
        { value: Consts.YOUTH, text: this.sessionTypeStr(Consts.YOUTH) },
        { value: Consts.OTHER, text: this.sessionTypeStr(Consts.OTHER) },
      ],

      headers: [
        { text: '#', value: 'reference', width: 40 },
        { text: 'Type', value: 'session_type', width: 100 },
        { text: 'Date(s)', value: 'sd.start' },
        { text: 'Organization', value: 'org.name' },
        { text: 'Class / Room', value: 'room' },
        { text: 'Technical setup', value: '', sortable: false },
        { text: 'Contact(s)', value: 'c.firstname' },
        { text: 'Trainer(s)', value: 'trainer.lastname' },
        { text: 'Status', value: 'status', width: 80 },
        { text: 'Actions', sortable: false, width: 160 }
      ],
      sessions: [],
      totalSessions: 0,
      loading: true,
      pagination: {
        sortBy: 'sd.start',
        descending: true,
        page: 1,
        rowsPerPage: 999999999
      },
      isTrainer: false,
      isCoord: false,
      isAdmin: false,
      editingSession: {},
      advancedSearch: null,
      params: {
        start: moment().format('YYYY-MM-DD'),
        end: moment().add(7, 'days').format('YYYY-MM-DD'),
        start_text: moment().format('DD.MM.YYYY'),
        end_text: moment().add(7, 'days').format('DD.MM.YYYY'),
        last: null,
        school: null,
        contact: null,
        address: null,
        event: null,
        trainers: [],
        status: [],
        session_type: [],
        target_group_id: [],
        topic_id: [],
        school_type_id: [],
        not_fully_eval: null,
      },
      status: [
        { value: Consts.CANCELLED, text: "Cancelled" },
        { value: Consts.CONFIRMED, text: "Confirmed" },
        { value: Consts.RELEASE, text: "Release" },
        { value: Consts.TOVALIDATE, text: "To validate" },
        { value: Consts.BLOCKED, text: "Blocked" },
        { value: Consts.TOEVALUATE, text: "To evaluate" },
        { value: Consts.CLOSED, text: "Closed" }
      ],
      groups: [],
      schoolTypes: [],
      keywords: null,
      init: true,
      all: false,
      selected: [],
      loadingPrint: false,
      loadingSess: null,
      trainers: [],
      Consts,
      storeKey: 'beesecureLogParams',
      storeKey2: 'beesecureLogPage',
      source: null,
    }
  },

  computed: {
    filtersArray () {
      let params = Object.assign({}, this.pagination, this.params, { keywords: this.keywords }, { logistics: true })

      return params
    }
  },

  watch: {
    pagination: {
      handler () {
        if (!this.init) {
          this.fetch()
        }
      },
      deep: true
    },
    keywords (val) {
      if (val && val.length > 2) {
        this.fetch()
      }
      else if (val == "") {
        this.fetch()
      }
    },
  },

  beforeMount () {
    this.advancedSearch = !(localStorage.advancedSearchLog === 'false')
  },

  mounted () {
    this.loadUser()
    this.fetch()
    this.fetchParams()
  },

  methods: {
    americanizeDate (date) {
      if (!date) return null

      if (date.indexOf('.') >= 0) {
        return date
      }

      return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')
    },
    parseDate (date) {
      if (!date) return null

      if (date.indexOf('-') >= 0) {
        return date
      }

      return moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD')
    },
    fetch () {
      if (this.init) {
        this.init = false
        this.onSelectMode()
        return
      }

      if(!this.params.start){
        this.params.start = moment().format('YYYY-MM-DD')
        this.params.start_text = moment().format('DD.MM.YYYY')
      }
      if(!this.params.end){
        this.params.end = moment().add(7, 'days').format('YYYY-MM-DD')
        this.params.end_text = moment().add(7, 'days').format('DD.MM.YYYY')
      }

      this.loading = true

      // cancel pending call
      clearTimeout(this._timerId)

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        if (this.source) {
          this.source.cancel()
        }

        let CancelToken = axios.CancelToken
        this.source = CancelToken.source()

        axios.get('/sessions', {
          cancelToken: this.source.token,
          params: this.filtersArray
        }).then(res => {
          this.sessions = res.data.items
          this.totalSessions = res.data.total

          for (let i = 0; i < this.sessions.length; ++i) {
            let s = this.sessions[i]

            // Parse dates into split date/time
            for (let i = 0; i < s.dates.length; ++i) {
              let d = s.dates[i]

              d.date_start = moment(d.start).format('YYYY-MM-DD')
              d.clock_start = moment(d.start).format('HH:mm:ss')
              d.clock_end = moment(d.end).format('HH:mm:ss')

              s.dates[i] = d
            }
            s.dates.sort((a, b) => (a.start < b.start) ? 1 : ((b.start < a.start) ? -1 : 0))
            this.sessions[i] = s
          }
          this.selected = this.sessions
          this.loading = false
          localStorage.setItem(this.storeKey2, JSON.stringify(this.pagination))
          localStorage.setItem(this.storeKey, JSON.stringify(this.params))
        }).catch(() => {

        })
      }, 350)
    },
    fetchParams () {
      Params.getList('schools-types', { is_enabled: 1 }).then(res => {
        this.schoolTypes = res.data
      })
      Params.getList('groups', { role: Consts.TRAINER }).then(res => {
        this.groups = res.data

        let groups = res.data
        for (let i = 0; i < groups.length; i++) {
          if (groups[i].users && groups[i].users.length > 0) {
            for (let j = 0; j < groups[i].users.length; j++) {
              let is = this.trainers.map(function (e) { return e.user_id }).indexOf(groups[i].users[j].id)

              if (is == -1 && groups[i].users[j].is_enabled) {
                groups[i].users[j]['fullname'] = groups[i].users[j]['firstname'] + " " + groups[i].users[j]['lastname']
                this.trainers.push(groups[i].users[j])
              }
            }
          }
        }
        this.trainers = this.trainers.sort((a, b) => (a.firstname > b.firstname) ? 1 : ((b.firstname > a.firstname) ? -1 : 0))

        //Supprime les doublons dans plusieurs groupes
        var uniq = new Set(this.trainers.map(e => JSON.stringify(e)))
        var result = Array.from(uniq).map(e => JSON.parse(e))
        this.trainers = result
      })
    },
    isEvent (s) {
      if (s.session_type != Consts.EVENTS) {
        return false
      }
      return true
    },
    getSessionDate (s) {
      let out = ''

      if (s.length === 0) {
        out += '-'
      } else if (s.length >= 1) {
        const d = s[0]
        out += this.formatDate(d.start) + ' [' + this.formatTime(d.start) + ' - ' + this.formatTime(d.end) + ']'
      }

      if (s.length > 1) {
        out += ' (+' + (s.length - 1) + ' other)'
      }

      return out
    },
    closeDates (s) {
      s.dates.sort((a, b) => (a.start < b.start) ? 1 : ((b.start < a.start) ? -1 : 0))
      s.date_popover_open = false
    },
    openDates (s) {
      s.dates.sort((a, b) => (a.start > b.start) ? 1 : ((b.start > a.start) ? -1 : 0))
      s.date_popover_open = true
    },

    removeDate (item, i) {
      item.dates.splice(i)
    },

    addDate (item) {
      item.dates.push({})
    },

    saveEvent (item) {
      for (let i = 0; i < item.dates.length; ++i) {
        const d = item.dates[i]

        d.start = d.date_start + ' ' + d.clock_start
        d.end = d.date_start + ' ' + d.clock_end

        item.dates[i] = d
      }

      Sessions.update(item.id, item).then(() => {
        this.$snotify.success('The session has been updated')
        this.fetch()
      })
    },
    print () {
      let params = []
      for (let i = 0; i < this.selected.length; i++) {
        params.push(this.selected[i].id)
      }
      if (params.length > 0) {
        this.loadingPrint = true
        Sessions.logistics({ sessions_id: params, sortBy: this.pagination.sortBy, descending: this.pagination.descending }).then(res => {
          let blob = new Blob([res.data], { type: "octet/stream" })

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, "Logistics-" + moment().format('YYYY-MM-DD-hh-mm-ss') + ".pdf")
          } else {
            let a = document.createElement("a")
            document.body.appendChild(a)
            a.style = "display: none"
            let url = window.URL.createObjectURL(blob)
            a.href = url
            a.download = "Logistics-" + moment().format('YYYY-MM-DD-hh-mm-ss') + ".pdf"
            a.click()
            window.URL.revokeObjectURL(url)
          }

          this.loadingPrint = false
        })
      }
    },

    printSession (sess) {
      this.loadingPrint = true
      this.loadingSess = sess.id
      Sessions.logistics({ sessions_id: [sess.id] }).then(res => {
        let a = document.createElement("a")
        document.body.appendChild(a)
        a.style = "display: none"

        let blob = new Blob([res.data], { type: "octet/stream" }),
          url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = "Logistics-" + moment().format('YYYY-MM-DD-hh-mm-ss') + ".pdf"
        a.click()
        window.URL.revokeObjectURL(url)
        this.loadingPrint = false
        this.loadingSess = null
      })
    },

    loadUser () {
      if (Auth.user.is_admin) {
        this.isAdmin = true
      }
      if (Auth.user.roles.indexOf(Consts.COORDINATION) > -1) {
        this.isCoord = true
      }
      if (Auth.user.roles.indexOf(Consts.TRAINER) > -1) {
        this.isTrainer = true
      }
    },

    onSelectMode (reset = false) {
      let json = JSON.parse(localStorage.getItem(this.storeKey))
      let json2 = JSON.parse(localStorage.getItem(this.storeKey2))
      if (!reset && json) {
        this.params = json

        if (json2) {
          this.pagination = json2
        }
      }
      else {
        this.params = {
          start: moment().format('YYYY-MM-DD'),
          end: moment().add(7, 'days').format('YYYY-MM-DD'),
          start_text: moment().format('DD.MM.YYYY'),
          end_text: moment().add(7, 'days').format('DD.MM.YYYY'),
          last: null,
          school: null,
          contact: null,
          address: null,
          event: null,
          trainers: [],
          status: [],
          session_type: [],
          target_group_id: [],
          topic_id: [],
          school_type_id: [],
          not_fully_eval: null,
          reference: null
        }

        this.params.status = [
          Consts.CONFIRMED,
          Consts.RELEASE,
          Consts.TOVALIDATE,
          Consts.BLOCKED,
          Consts.TOEVALUATE
        ]

        this.pagination.sortBy = "sd.start"
        this.pagination.descending = true

        if (reset) {
          this.params.start = moment().format('YYYY-MM-DD')
          this.params.end = moment().add(7, 'days').format('YYYY-MM-DD')
          this.params.start_text = moment().format('DD.MM.YYYY')
          this.params.end_text = moment().add(7, 'days').format('DD.MM.YYYY')
        }
      }

      this.fetch()
    },

    toggleAdvancedSearch () {
      this.advancedSearch = !this.advancedSearch
      localStorage.advancedSearchLog = this.advancedSearch
    },
  }
}
</script>

<style scoped>
.eval-block {
  background: #d6d6d6;
  width: 8px;
  height: 24px;
}

.toolbar-toggle {
  cursor: pointer;
  position: absolute;
  background: white;
  width: 40px;
  height: 16px;
  line-height: 0;
  text-align: center;
  left: calc(50% - 20px);
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.select-mode > .v-input__control > .v-input__slot {
  width: fit-content;
}

.printable {
  display: none;
}

@media print {
  .index-header,
  .index-footer,
  .v-icon,
  .no-printable,
  .v-datatable thead th.column.sortable i {
    display: none;
  }
  .printable {
    display: block;
  }
}
</style>
